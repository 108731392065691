import { Avatar, Button, Col, Row, Typography, ConfigProvider } from "antd";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  finalTotal,
  encodeParamString,
  formatPrice,
} from "../const/const_functions";
import { useCarts } from "../contexts/Carts";
import EditItemsCartModal from "./EditItemsCartModal";

const SingleCartCard = ({ cart, multiCartCopy }) => {
  const { multiCart, setMultiCartData } = useCarts();
  const { theme } = useContext(ConfigProvider.ConfigContext);
  const navigate = useNavigate();
  const { Text, Link } = Typography;
  const { stadiumID, stadiumName } = cart;
  const [showEditItemsModal, setShowEditItemsModal] = useState(false);
  let quantity = null;

  let storeType;
  if (cart.stadTypes.includes("STT_RESTO")) {
    storeType = "food";
    quantity = cart.foodItems.reduce(
      (p, { quantityToPurchase }) => p + quantityToPurchase,
      0
    );
  } else if (cart.stadTypes.includes("STT_VENUE")) {
    storeType = "venue";
    let evQuantity = 0;
    if (cart.eventItems) {
      cart.eventItems.forEach((item) => {
        let admQuantity = 0;
        admQuantity = item.admissionPurchaseQuantities.reduce(
          (p, { quantityToPurchase }) => p + quantityToPurchase,
          0
        );
        evQuantity = evQuantity + admQuantity;
      });
    }
    let foodQuantity = 0;
    if (cart.foodItems) {
      foodQuantity = cart.foodItems.reduce(
        (p, { quantityToPurchase }) => p + quantityToPurchase,
        0
      );
    }
    quantity = foodQuantity + evQuantity;
  } else if (cart.stadTypes.includes("STT_RETAIL")) {
    storeType = "retail";
    quantity = cart.merchItems.reduce(
      (p, { quantityToPurchase }) => p + quantityToPurchase,
      0
    );
  }

  const removeCart = () => {
    const newMultiCart = multiCart.filter(
      (mCart) => mCart.stadiumID !== cart.stadiumID
    );
    setMultiCartData(newMultiCart);
  };

  const openStorePage = () => {
    const encodedStoreId = encodeParamString(stadiumID);
    navigate(
      `/order/${stadiumName.replaceAll(" ", "-")}?glb=${encodedStoreId}`,
      false
    );
  };

  return (
    <Row className="cartItemRow">
      <Row style={{ height: 60, width: "100%" }}>
        <Col span={4}>
          <img
            src={cart.stadiumLogoUrl}
            alt="Store Logo"
            width={"100%"}
            style={{ objectFit: "contain" }}
          />
        </Col>
        <Col span={20} style={{ paddingLeft: 10, height: 20 }}>
          <Row
            style={{ width: "100%", flexWrap: "nowrap" }}
            justify="space-between"
          >
            <Col onClick={() => openStorePage()}>
              <Link
                className="stadiumName"
                style={{
                  color: theme.token.colorSecondary,
                  lineHeight: "normal",
                }}
              >
                {cart.stadiumName}
              </Link>
            </Col>
            <Col>
              <Text
                className="removeBtn"
                style={{ whiteSpace: "nowrap" }}
                onClick={() => removeCart()}
              >
                Remove
              </Text>
            </Col>
          </Row>
          <Row style={{ width: "80%" }}>
            <Text className="stadiumAddress">{cart.stadiumAddress}</Text>
          </Row>
        </Col>
      </Row>
      <Row style={{ width: "100%", paddingTop: 20 }}>
        <Col span={24}>
          <Row justify="end">
            <Text className="foodItemsText">
              {quantity && quantity}
              {quantity > 1 ? " Items" : " Item"}
            </Text>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="end">
            <Text className="foodItemsText">
              Cart Total: {formatPrice(finalTotal(cart, storeType))}
            </Text>
          </Row>
        </Col>
      </Row>
      <Row className="editCheckoutSection" justify="end" style={{}}>
        <Text
          className="editItemsText"
          style={{ color: theme.token.colorSecondary }}
          onClick={() => setShowEditItemsModal(!showEditItemsModal)}
        >
          Edit Items
        </Text>
        <Button
          className="checkoutBtn"
          style={{ color: theme.token.colorTertiary }}
          size="large"
          type="primary"
          onClick={() =>
            navigate(
              `/checkout/${cart.stadiumName.replaceAll(" ", "-")}?glb=${
                cart.stadiumID
              }`,
              {
                state: {
                  cart: cart,
                },
              }
            )
          }
        >
          Checkout
        </Button>
      </Row>
      <Row style={{ width: "100%" }}>
        <EditItemsCartModal
          showEditItemsModal={showEditItemsModal}
          setShowEditItemsModal={setShowEditItemsModal}
          storeType={storeType}
          cart={cart}
          multiCart={multiCart}
          setMultiCartData={setMultiCartData}
          multiCartCopy={multiCartCopy}
        />
      </Row>
    </Row>
  );
};

export default SingleCartCard;
