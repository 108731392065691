import React, { useState, useContext } from "react";
import { Row, Col, Image, ConfigProvider, Typography, Drawer } from "antd";
import { useBrandContext } from "../../../contexts/Brand";
import { useChain } from "../../../contexts/Chain";
import { screenWidths } from "../../../const/const_functions";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { FaInfoCircle, FaQuestionCircle } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import AnnouncementDrawer from "../../AnnouncementDrawer";
import DonateDrawer from "../../DonateDrawer";
import TrackOrderModal from "../../TrackOrderModal";

const HeaderDeskVar1 = ({ executeScroll }) => {
  const navigate = useNavigate();
  const { theme } = useContext(ConfigProvider.ConfigContext);
  const { brandContextData, loadingBrandContextData } = useBrandContext();
  const { featuredItems, locationSelected } = useChain();
  const { Text, Link } = Typography;
  const location = useLocation();

  const [showHeaderDrawer, setShowHeaderDrawer] = useState(false);
  const [showAnnouncementDrawer, setShowAnnouncementDrawer] = useState(false);
  const [showDonateDrawer, setShowDonateDrawer] = useState(false);
  const [showTrackOrderModal, setShowTrackOrderModal] = useState(false);

  let smallScreenSize = false;
  const { width } = useWindowDimensions();
  if (screenWidths.xl > width) {
    smallScreenSize = true;
  }

  // If we are in the context of getit payments, do not show the header on these pages
  if (
    (location.pathname.includes("/order") ||
      location.pathname.includes("/checkout") ||
      location.pathname.includes("/pay") ||
      location.pathname.includes("/myorders")) &&
    brandContextData._id === "a5121e36-getit-technologies"
  ) {
    return null;
  }

  const _renderSocialIcon = (passedSocialLink, passedIndex) => {
    if (!passedSocialLink.iconName || !passedSocialLink.navUrl) return null;
    const iconColourStyle = brandContextData.brandTheme.colorPrimary
      ? brandContextData.brandTheme.colorPrimary
      : "black";

    const iconLink = passedSocialLink.iconName;
    const iconSize = "2em";
    return (
      <Col key={passedIndex}>
        <Link
          href={passedSocialLink.navUrl}
          target={"blank"}
          style={{ color: iconColourStyle }}
        >
          <img style={{ width: iconSize, height: iconSize }} src={iconLink} />
        </Link>
      </Col>
    );
  };

  const { ghostEventStores, stadiumListing } = brandContextData;
  let stadiums = [];
  if (!brandContextData.isChain && stadiumListing?.length) {
    stadiums = stadiumListing.filter(
      (fStadium) =>
        fStadium._id !== "k8dfXum85newkBBE4" &&
        fStadium._id !== "GYT54hH3dxCQ7PNSy"
    );
    if (ghostEventStores) {
      stadiums = stadiums.filter(
        (fStadium) => !fStadium.stadTypes.includes("STT_VENUE")
      );
    }
  }

  let customLogoWidth = 130;

  if (
    brandContextData._id === "65a7f4cab8d346fb4ac4671c" ||
    brandContextData._id === "65a9429aa534359159e0b348"
  ) {
    customLogoWidth = 250;
  }

  if (smallScreenSize) {
    customLogoWidth = 110;

    if (
      brandContextData._id === "65a7f4cab8d346fb4ac4671c" ||
      brandContextData._id === "65a9429aa534359159e0b348"
    ) {
      customLogoWidth = 200;
    }
  }

  return (
    <Row
      style={{
        backgroundColor: theme.token.colorPrimary,
        padding: 15,
        justifyContent: "center",
      }}
    >
      <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
        <Row style={{ width: 1200 }}>
          <Col span={24}>
            <Row justify={"space-between"} align={"middle"}>
              <Col>
                {!loadingBrandContextData && brandContextData.brandLogo ? (
                  <Image
                    onClick={() => navigate("/")}
                    width={customLogoWidth}
                    preview={false}
                    src={brandContextData.brandLogo}
                    className="headerSolutionLogo"
                    style={{
                      objectPosition: "left",
                      maxHeight: 75,
                      minHeight: 50,
                    }}
                  />
                ) : null}
              </Col>
              <Col>
                <Row>
                  <Row
                    onClick={() => {
                      if (location.pathname === "/") {
                        if (
                          (!brandContextData.isChain && stadiums.length) ||
                          (brandContextData.isChain &&
                            featuredItems?.length &&
                            locationSelected)
                        ) {
                          executeScroll();
                        }
                      } else {
                        navigate("/");
                      }
                    }}
                  >
                    <Text
                      target="blank"
                      href="mailto:support@cusa.com"
                      style={{
                        color: theme.token.colorTertiary,
                        cursor: "pointer",
                        fontWeight: 600,
                        fontSize: smallScreenSize ? 14 : 16,
                        borderRightColor: theme.token.colorTertiary,
                      }}
                    >
                      Order Now
                    </Text>
                    <Text
                      style={{
                        paddingLeft: smallScreenSize ? 10 : 20,
                        paddingRight: smallScreenSize ? 10 : 20,
                        fontSize: smallScreenSize ? 14 : 16,
                        color: theme.token.colorTertiary,
                      }}
                    >
                      |
                    </Text>
                  </Row>
                  <Row
                    onClick={() => setShowTrackOrderModal(!showTrackOrderModal)}
                  >
                    <Text
                      target="blank"
                      href="mailto:support@cusa.com"
                      style={{
                        color: theme.token.colorTertiary,
                        cursor: "pointer",
                        fontWeight: 600,
                        fontSize: smallScreenSize ? 14 : 16,
                      }}
                    >
                      View Order
                    </Text>
                    <Text
                      style={{
                        paddingLeft: smallScreenSize ? 10 : 20,
                        paddingRight: smallScreenSize ? 10 : 20,
                        fontSize: smallScreenSize ? 14 : 16,
                        color: theme.token.colorTertiary,
                      }}
                    >
                      |
                    </Text>
                  </Row>
                  {!loadingBrandContextData &&
                  brandContextData?.announcements?.length > 0 ? (
                    <Row
                      onClick={() =>
                        setShowAnnouncementDrawer(!showAnnouncementDrawer)
                      }
                    >
                      <Text
                        target="blank"
                        href="mailto:support@cusa.com"
                        style={{
                          color: theme.token.colorTertiary,
                          cursor: "pointer",
                          fontWeight: 600,
                          fontSize: smallScreenSize ? 14 : 16,
                        }}
                      >
                        Announcements
                      </Text>
                      <Text
                        style={{
                          paddingLeft: smallScreenSize ? 10 : 20,
                          paddingRight: smallScreenSize ? 10 : 20,
                          fontSize: smallScreenSize ? 14 : 16,
                          color: theme.token.colorTertiary,
                        }}
                      >
                        |
                      </Text>
                    </Row>
                  ) : null}
                  {!loadingBrandContextData &&
                  brandContextData.acceptsDonations ? (
                    <Row onClick={() => setShowDonateDrawer(!showDonateDrawer)}>
                      <Text
                        style={{
                          color: theme.token.colorTertiary,
                          cursor: "pointer",
                          fontWeight: 600,
                          fontSize: smallScreenSize ? 14 : 16,
                        }}
                      >
                        Donations
                      </Text>
                      <Text
                        style={{
                          paddingLeft: smallScreenSize ? 10 : 20,
                          paddingRight: smallScreenSize ? 10 : 20,
                          fontSize: smallScreenSize ? 14 : 16,
                          color: theme.token.colorTertiary,
                        }}
                      >
                        |
                      </Text>
                    </Row>
                  ) : null}
                  <Row onClick={() => navigate("/cart")}>
                    <Text
                      style={{
                        color: theme.token.colorTertiary,
                        cursor: "pointer",
                        fontWeight: 600,
                        fontSize: smallScreenSize ? 14 : 16,
                        paddingRight: smallScreenSize ? 10 : 20,
                      }}
                    >
                      Cart
                    </Text>
                  </Row>
                  <Row align={"middle"}>
                    <AiOutlineMenu
                      onClick={() => setShowHeaderDrawer(!showHeaderDrawer)}
                      style={{
                        fontSize: smallScreenSize ? 18 : 20,
                        color: theme.token.colorTertiary,
                        cursor: "pointer",
                      }}
                    />
                  </Row>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Drawer
        placement="right"
        onClose={() => setShowHeaderDrawer(!showHeaderDrawer)}
        open={showHeaderDrawer}
      >
        {!loadingBrandContextData && brandContextData?.showAboutButton ? (
          <Row
            style={{ cursor: "pointer", paddingBottom: 30 }}
            align={"middle"}
            onClick={() => {
              setShowHeaderDrawer(false);
              navigate("/about-us");
            }}
          >
            <FaInfoCircle style={{ fontSize: 24, paddingRight: 10 }} />
            <Text style={{ fontWeight: 600, fontSize: 16 }}>About Us</Text>
          </Row>
        ) : null}
        <Row style={{ cursor: "pointer", paddingBottom: 5 }} align={"middle"}>
          <FaQuestionCircle style={{ fontSize: 24, paddingRight: 10 }} />
          <Text style={{ fontWeight: 600, fontSize: 16 }}>Support</Text>
        </Row>
        <Col style={{ paddingLeft: 34 }}>
          <Text>tel: 343-809-4400</Text>
        </Col>
        {/* <Col style={{ paddingLeft: 34 }}>
          <Link
            target="blank"
            href="mailto:support@cusa.com"
            style={{ color: "black", cursor: "pointer" }}
          >
            email: support@cusa.com
          </Link>
        </Col> */}
        {!loadingBrandContextData && brandContextData.brandSocial?.length ? (
          <Col style={{ bottom: 10, position: "absolute" }}>
            <Text style={{ fontWeight: "bold" }}>Let's Connect!</Text>
            <Row gutter={6} style={{ paddingTop: 5 }}>
              {brandContextData.brandSocial.map((socialLink, index) =>
                _renderSocialIcon(socialLink, index)
              )}
            </Row>
          </Col>
        ) : null}
      </Drawer>
      <AnnouncementDrawer
        showAnnouncementDrawer={showAnnouncementDrawer}
        brandContextData={brandContextData}
        setShowAnnouncementDrawer={setShowAnnouncementDrawer}
      />
      <DonateDrawer
        showDrawer={showDonateDrawer}
        brandContextData={brandContextData}
        setShowDrawer={setShowDonateDrawer}
        loadingBrandContextData={loadingBrandContextData}
        mobile={true}
      />
      <TrackOrderModal
        setShowTrackOrderModal={setShowTrackOrderModal}
        showTrackOrderModal={showTrackOrderModal}
      />
    </Row>
  );
};

export default HeaderDeskVar1;
