import React, { useEffect, useRef } from "react";
import { ConfigProvider } from "antd";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import App from "./App";
import WhiteLabelFooter from "./WhiteLabelFooter";
import Cart from "./pages/Cart";
import StoreFront from "./pages/StoreFront";
import Checkout from "./pages/Checkout";
import Payment from "./pages/Payment";
// Available Headers
import WhiteLabelHeader from "./components/WhiteLabelHeaderNew";
import WhiteLabelHeaderVariant1Desktop from "./components/headers/desktop/HeaderDeskVar1";
import WhiteLabelHeaderVariant1Mobile from "./components/headers/mobile/HeaderVar1";

import { useBrandContext } from "./contexts/Brand";
import { useChain } from "./contexts/Chain";
import { Helmet } from "react-helmet";
import OrderHistoryDetails from "./pages/OrderHistoryDetails";
import EventOverview from "./pages/EventOverview";
import SelectLocationSection from "./components/SelectLocationSection";
import useWindowDimensions from "./hooks/useWindowDimensions";
import { screenWidths } from "./const/const_functions";
import AboutUs from "./pages/AboutUs";
import Invoice from "./pages/Invoice";
import InvoicePayment from "./pages/InvoicePayment";
import PaymentConfirmation from "./pages/PaymentConfirmation";
import Donation from "./pages/Donation";
import CreateAccount from "./pages/CreateAccount";
import Login from "./pages/Login";
import EditAccount from "./pages/EditAccount";
import EventList from "./pages/EventList";

const AppSetup = () => {
  const { brandContextData } = useBrandContext();
  const { locationSelected } = useChain();
  const myRef = useRef(null);
  const eventsRef = useRef(null);
  let mobile = false;
  let isInvoicePage = false;
  if (
    window.location.pathname.includes("invoice") ||
    window.location.pathname.includes("payinvoice") ||
    window.location.pathname.includes("invoiceconfirmation") ||
    window.location.pathname.includes("register") ||
    window.location.pathname.includes("login") ||
    window.location.pathname.includes("edit-account")
  ) {
    isInvoicePage = true;
  }

  const { width } = useWindowDimensions();
  if (screenWidths.md > width) {
    mobile = true;
  }

  const executeScroll = () => {
    myRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const executeScrollToEvents = () => {
    if (eventsRef.current) {
      eventsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    document.title = "Order Now";
    if (brandContextData) {
      document.title = `${brandContextData.brandName} Ordering`;
    }
  }, [brandContextData]);

  if (!brandContextData) {
    return null;
  }

  // if (process.env.REACT_APP_BUILD_MODE === "prod") {
  //   return <div>Nothing to see here</div>;
  // }

  const headerToRender = () => {
    if (mobile) {
      if (brandContextData?.mobileHeaderType === "header-layout-1") {
        return <WhiteLabelHeaderVariant1Mobile executeScroll={executeScroll} />;
      }
    } else {
      if (brandContextData?.desktopHeaderType === "header-layout-1") {
        return (
          <WhiteLabelHeaderVariant1Desktop executeScroll={executeScroll} />
        );
      }
    }
    return <WhiteLabelHeader executeScroll={executeScroll} />;
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: brandContextData
            ? brandContextData.brandTheme.colorPrimary
            : "#ffffff",
          colorSecondary: brandContextData
            ? brandContextData.brandTheme.colorSecondary
            : "#ffffff",
          colorTertiary: brandContextData
            ? brandContextData.brandTheme.colorTertiary
            : "#ffffff",
        },
        components: {
          Radio: {
            colorPrimary: "black",
          },
          Checkbox: {
            colorPrimary: "black",
            colorPrimaryHover: "black",
            colorPrimaryBorderHover: "black",
          },
        },
      }}
    >
      <BrowserRouter>
        <div style={{ minHeight: "100vh", position: "relative" }}>
          <div style={{ paddingBottom: "8rem" }}>
            <ScrollToTop />
            {!isInvoicePage ? headerToRender() : null}
            {brandContextData.isChain && !isInvoicePage ? (
              <SelectLocationSection mobile={mobile} />
            ) : null}
            <Routes>
              <Route>
                <Route
                  path="/"
                  element={
                    <App
                      refProp={myRef}
                      eventsRefProp={eventsRef}
                      executeScroll={executeScroll}
                      executeScrollToEvents={executeScrollToEvents}
                    />
                  }
                />
                <Route path="/order/:id" element={<StoreFront />} />
                <Route path="/donate" element={<Donation />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/checkout/:id" element={<Checkout />} />
                <Route path="/pay" element={<Payment />} />
                <Route
                  path="/myorders/:orderNo"
                  element={<OrderHistoryDetails />}
                />
                <Route path="/event/:id" element={<EventOverview />} />
                <Route path="/event/list" element={<EventList />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route
                  path="/:encodedStadiumId/invoice"
                  element={<Invoice />}
                />
                <Route
                  path="/:encodedOrderId/payinvoice"
                  element={<InvoicePayment />}
                />
                <Route
                  path="/:encodedOrderId/invoiceconfirmation"
                  element={<PaymentConfirmation />}
                />
                <Route
                  path="/:encodedStadiumId/register"
                  element={<CreateAccount />}
                />
                <Route path="/login" element={<Login />} />
                <Route path="/edit-account" element={<EditAccount />} />
                <Route
                  path="/error"
                  element={
                    <main style={{ padding: "1rem" }}>
                      <p>There's nothing here, double check the link!</p>
                    </main>
                  }
                />
                <Route
                  path="*"
                  element={
                    <main style={{ padding: "1rem" }}>
                      <p>There's nothing here!</p>
                    </main>
                  }
                />
              </Route>
            </Routes>
          </div>

          {!isInvoicePage ? <WhiteLabelFooter /> : null}
        </div>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default AppSetup;
