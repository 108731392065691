import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Row,
  Col,
  Button,
  Avatar,
  ConfigProvider,
  message,
  Image,
} from "antd";
import { CgMathPlus, CgMathMinus } from "react-icons/cg";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { formatPrice, screenWidths } from "../const/const_functions";
import { useCarts } from "../contexts/Carts";
import styles from "../styles/RetailItemModal.module.css";
import VariationItem from "./VariationItem";

const ProductVariantCard = ({
  setShowRetailItemModal,
  currentItem,
  stadium,
  shopType,
}) => {
  const { theme } = useContext(ConfigProvider.ConfigContext);
  const { Text } = Typography;
  const { multiCart, setMultiCartData } = useCarts();
  const { width } = useWindowDimensions();
  const { Ptitle, DefaultImages, Description, SkuHandle, lowestPriceVeep } =
    currentItem;
  const [startQuantity, setStartQuantity] = useState(1);
  const quantityFlag = startQuantity > 1;

  const [variant1, setVariant1] = useState({
    ...currentItem.Variant1,
    variantNum: 1,
  });
  const [variant2, setVariant2] = useState({
    ...currentItem.Variant2,
    variantNum: 2,
  });
  const [variant3, setVariant3] = useState({
    ...currentItem.Variant3,
    variantNum: 3,
  });
  const [selectedVariant1, setSelectedVariant1] = useState(null);
  const [selectedVariant2, setSelectedVariant2] = useState(null);
  const [selectedVariant3, setSelectedVariant3] = useState(null);
  const [activeVeepToDisplay, setActiveVeepToDisplay] = useState(null);

  useEffect(() => {
    checkForPrice();
  }, [variant1, variant2, variant3]);

  const variantList = [];
  if (currentItem.Variant1) {
    variantList.push(variant1);
  }
  if (currentItem.Variant2) {
    variantList.push(variant2);
  }
  if (currentItem.Variant3) {
    variantList.push(variant3);
  }

  let imgUrl = "";
  if (typeof DefaultImages[0] === "string" && DefaultImages[0].trim() !== "") {
    imgUrl = DefaultImages[0];
  }
  const price = (Number(lowestPriceVeep.VeepPriceCents) / 100).toFixed(2);

  let mySaneDesc = "";
  if (typeof Description === "string" && Description.trim() !== "") {
    mySaneDesc = Description.trim();
  }

  const findVariantInVeepMap = (fullVeepKey) => {
    const veepVariantToDisplay = currentItem.VeepListForMap.find((veep) => {
      return veep.VeepMapKey === fullVeepKey;
    });
    setActiveVeepToDisplay(veepVariantToDisplay);
    setStartQuantity(1);
  };

  const checkForPrice = () => {
    let veepMapKey = currentItem.SkuHandle.concat("||");
    if (variant1 && variant1.isSelected && !variant2.variantName) {
      let fullVeepKey = veepMapKey.concat(selectedVariant1);
      findVariantInVeepMap(fullVeepKey);
    } else if (
      variant1 &&
      variant1.isSelected &&
      variant2 &&
      variant2.isSelected &&
      !variant3.variantName
    ) {
      let fullVeepKey = veepMapKey
        .concat(selectedVariant1)
        .concat("||")
        .concat(selectedVariant2);
      findVariantInVeepMap(fullVeepKey);
    } else if (
      variant1 &&
      variant1.isSelected &&
      variant2 &&
      variant2.isSelected &&
      variant3 &&
      variant3.isSelected
    ) {
      let fullVeepKey = veepMapKey
        .concat(selectedVariant1)
        .concat("||")
        .concat(selectedVariant2)
        .concat("||")
        .concat(selectedVariant3);
      findVariantInVeepMap(fullVeepKey);
    }
  };

  // Check which variant was selected and assign it to it's respective state variable
  const selectVariant = (val, variantNum) => {
    if (variantNum === 1) {
      setSelectedVariant1(val);
      setVariant1({
        ...variant1, // keep all other key-value pairs
        isSelected: true, // update the value of specific key
        selectedVariant: val,
      });
    } else if (variantNum === 2) {
      setSelectedVariant2(val);
      setVariant2({
        ...variant2, // keep all other key-value pairs
        isSelected: true, // update the value of specific key
        selectedVariant: val,
      });
    } else if (variantNum === 3) {
      setSelectedVariant3(val);
      setVariant3({
        ...variant3, // keep all other key-value pairs
        isSelected: true, // update the value of specific key
        selectedVariant: val,
      });
    }
  };

  const onClickPlus = () => {
    if (
      activeVeepToDisplay &&
      startQuantity < activeVeepToDisplay.VeepQuantity
    ) {
      const newQuantity = startQuantity + 1;
      setStartQuantity(newQuantity);
    }
  };

  const onClickMinus = () => {
    if (startQuantity > 1) {
      const newQuantity = startQuantity - 1;
      setStartQuantity(newQuantity);
    }
  };

  const createNewCart = (time, currentDate) => {
    const createdCart = {};
    createdCart.stadiumID = stadium._id;
    createdCart.stadiumName = stadium.name;
    createdCart.stadiumAddress = stadium.address;
    createdCart.stadiumLogoUrl = stadium.imageUrl;
    createdCart.stadiumFeaturedImage = stadium.stadiumFeaturedImage;
    createdCart.stadiumIsOpen = stadium.isOpen;
    createdCart.stadTypes = stadium.stadTypes;
    createdCart.createdAt = currentDate;

    // Push the current retail item onto the current cart
    createdCart.merchItems = [];
    createdCart.merchItems.push({
      apfRenderId: time,
      retailItemId: currentItem._id,
      SkuHandle: SkuHandle,
      veepMapKey: activeVeepToDisplay.VeepMapKey,
      price: Number(activeVeepToDisplay.VeepPriceCents) / 100,
      quantityToPurchase: startQuantity,
      veepQuantity: activeVeepToDisplay.VeepQuantity,
      variant1: variant1,
      variant2: variant2,
      variant3: variant3,
      pTitle: Ptitle,
      image: DefaultImages[0],
    });
    return createdCart;
  };

  const addRetailItemToCart = () => {
    // Used in order to make the items unique when added to the cart
    // Some cases the user may go back and add the same item.
    // in this case, we cannot use the item's 'id'
    const d = new Date();
    const time = d.getTime();

    // Boolean to check if there is a cart in the multicart with the same stadiumid
    // Determine if we are going to have to create a new cart, or add
    // a new cart to the multicart
    let sameStadiumObj = multiCart.find(
      (cart) => cart.stadiumID === stadium._id
    );
    if (sameStadiumObj) {
      // The user is adding another item from a stadium that is already in his carts array
      if (!sameStadiumObj.merchItems) sameStadiumObj.merchItems = [];
      const newRetailItem = {
        // Special id used for rendering the same item twice (frontend only)
        apfRenderId: time,
        retailItemId: currentItem._id,
        SkuHandle: SkuHandle,
        veepMapKey: activeVeepToDisplay.VeepMapKey,
        price: Number(activeVeepToDisplay.VeepPriceCents) / 100,
        quantityToPurchase: startQuantity,
        veepQuantity: activeVeepToDisplay.VeepQuantity,
        variant1: variant1,
        variant2: variant2,
        variant3: variant3,
        pTitle: Ptitle,
        image: DefaultImages[0],
      };

      sameStadiumObj.merchItems = [...sameStadiumObj.merchItems, newRetailItem];
      const newCartItems = multiCart.map((cart) =>
        cart.stadiumID === stadium._id ? sameStadiumObj : cart
      );
      setMultiCartData(newCartItems);
    } else {
      const createdCart = createNewCart(time, d);
      setMultiCartData([...multiCart, createdCart]);
    }
    setShowRetailItemModal(false);
    message.open({
      type: "success",
      content: `${startQuantity} x ${Ptitle} ${
        startQuantity > 1 ? "have" : " has"
      } been added to your cart`,
    });
  };

  return (
    <>
      <Text className={styles.name}>{shopType}</Text>
      <Row
        className={styles.titleRow}
        justify={width > screenWidths.md ? "start " : "center"}
        style={width < screenWidths.sm ? { alignItems: "center" } : null}
      >
        {imgUrl ? (
          <Col xs={{ span: 18 }} sm={{ span: 6 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingRight: 20,
                paddingBottom: 20,
              }}
            >
              {imgUrl && <Image src={imgUrl} className={styles.retailImg} />}
            </div>
          </Col>
        ) : null}
        <Col
          xs={{ span: 24 }}
          sm={{ span: imgUrl ? 18 : 24 }}
          className={styles.titleColumn}
        >
          <Row>
            <Col span={24}>
              <Text className={styles.name}>{Ptitle}</Text>
            </Col>
            <Col span={24}>
              <Text className={styles.textDesc}>{mySaneDesc}</Text>
            </Col>
          </Row>
          <Row style={{ paddingTop: 20 }}>
            <Text className={styles.price}>{formatPrice(Number(price))}</Text>
            <Text style={{ paddingTop: "2px" }}>min /each</Text>
          </Row>
        </Col>
      </Row>
      {variantList.length > 0 &&
        variantList.map((item) => {
          return (
            <Row className={styles.variants} key={item.variantName}>
              <VariationItem
                item={item}
                selectedVariant1={selectedVariant1}
                variant1={variant1}
                selectVariant={selectVariant}
                selectedVariant2={selectedVariant2}
                variant2={variant2}
                selectedVariant3={selectedVariant3}
                variant3={variant3}
              />
            </Row>
          );
        })}

      <Row className={styles.rowCenter}>
        <Col
          className={styles.quantityCol}
          xs={{ span: 22, offset: 1 }}
          md={{ span: 16, offset: 4 }}
        >
          <Button
            style={{
              borderColor: quantityFlag
                ? theme.token.colorPrimary
                : "lightgray",
              boxShadow: "2px 3px 4px #ccc",
            }}
            className={styles.quantityButtonMinus}
            disabled={!quantityFlag}
            onClick={() => onClickMinus()}
          >
            <CgMathMinus className={styles.quantityIcon} />
          </Button>
          <Text className={styles.quantityText}>{startQuantity}</Text>
          <Button
            style={{
              boxShadow: "2px 3px 4px #ccc",
              borderColor:
                activeVeepToDisplay &&
                startQuantity < activeVeepToDisplay.VeepQuantity
                  ? theme.token.colorPrimary
                  : "lightgray",
            }}
            disabled={
              activeVeepToDisplay &&
              startQuantity < activeVeepToDisplay.VeepQuantity
                ? false
                : true
            }
            className={styles.quantityButton}
            onClick={() => onClickPlus()}
          >
            <CgMathPlus className={styles.quantityIcon} />
          </Button>
        </Col>
      </Row>
      {activeVeepToDisplay &&
        Number(activeVeepToDisplay.VeepQuantity) < 5 &&
        Number(activeVeepToDisplay.VeepQuantity) > 0 && (
          <Row justify="center">
            <Text className={styles.textInStock}>
              Only {activeVeepToDisplay.VeepQuantity} Left in stock
            </Text>
          </Row>
        )}

      <Row className={styles.rowCenter}>
        <Col
          className={styles.quantityCol}
          xs={{ span: 24 }}
          md={{ span: 16, offset: 4 }}
        >
          {activeVeepToDisplay && activeVeepToDisplay.VeepQuantity <= 0 ? (
            <Button
              style={{
                backgroundColor: theme.token.colorPrimary,
                color: "white",
                marginBottom: 30,
              }}
              className={styles.addVariantButton}
            >
              Out of Stock
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: !activeVeepToDisplay
                  ? "lightgray"
                  : theme.token.colorSecondary,
                color: "white",
                marginBottom: 30,
              }}
              className={styles.addVariantButton}
              onClick={() =>
                activeVeepToDisplay ? addRetailItemToCart() : null
              }
            >
              {activeVeepToDisplay
                ? `Add to cart - ${formatPrice(
                    Number(
                      (activeVeepToDisplay.VeepPriceCents * startQuantity) / 100
                    )
                  )}`
                : "Add to cart"}
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ProductVariantCard;
