import { message } from "antd";
import React, { createContext, useState, useContext, useEffect } from "react";
import { useServer } from "./Server";

// Create the Auth Context with the data type specified
// and a empty object
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const { serverData } = useServer();
  const [authData, setAuthData] = useState();
  const [userDetails, setUserDetails] = useState();
  const [loadingAuth, setLoadingAuth] = useState(true);

  useEffect(() => {
    // Everytime a page is opened we check for a user
    if (serverData) {
      fetchAsync();
    }
  }, [serverData]);

  const loginWithToken = async (token) => {
    try {
      const userAuth = await serverData.login({ resume: token });
      return userAuth;
    } catch (e) {
      // There was an issue with the token that was stored in this users localstorage
      // Remove the token
      localStorage.removeItem("token");

      return null;
    }
  };

  const fetchAsync = async () => {
    let userDetails = null;
    let token = localStorage.getItem("token");

    const userAuth = await loginWithToken(token);

    if (userAuth) {
      // console.log("User is currently logged in");
      const userID = userAuth.id;
      userDetails = await serverData.call("fetchUserById", userID);
    } else {
      // console.log("User is NOT currently logged in");
    }
    setUserDetails(userDetails);
    setAuthData(userAuth);
    setLoadingAuth(false);
  };

  const loginWithUsernameAndPassword = async (values) => {
    // Extract username and password from the input fields
    const { username, password, email } = values;

    if (!email) {
      message.error("Please input an email");
      throw new Error("Error", "Please input an email");
    }

    if (!password) {
      message.error("Please input a password");
      throw new Error("Error", "Please input a password");
    }

    // Try to login to the Meteor Server
    // You must pass password and username
    let userAuth;
    try {
      userAuth = await serverData.login({
        password,
        user: {
          username,
        },
      });
    } catch (e) {
      if (e.message) {
        if (e.message.includes("Incorrect password")) {
          message.error("Incorrect email or password");
        } else {
          message.error(e.message);
        }
      }
      throw new Error("Error", "There was an error logging you in.");
    }

    const userID = userAuth.id;
    const details = await serverData.call("fetchUserById", userID);

    if (details.profile.roles !== "user") {
      message.info("This user is not of the correct role.");
      await serverData.logout();
      throw new Error("Unable to login this user");
    }

    // Successful login
    // Set the token in async storage so this user does not have to login again next time
    localStorage.setItem("token", userAuth.token);
    setAuthData(userAuth);

    fetchAsync();
  };

  const logoutUser = async () => {
    await serverData.logout();
    localStorage.removeItem("token");
    setAuthData(null);
    setUserDetails(null);
    message.success("Successful logout. Have a nice day.");
  };

  return (
    //This component will be used to encapsulate the whole App,
    //so all components will have access to the Context
    <AuthContext.Provider
      value={{
        fetchAsync,
        authData,
        loginWithUsernameAndPassword,
        logoutUser,
        userDetails,
        loadingAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

//A simple hooks to facilitate the access to the AuthContext
// and permit components to subscribe to AuthContext updates
function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export { AuthContext, AuthProvider, useAuth };
