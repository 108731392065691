import { Card, Col, Row, Typography, ConfigProvider } from "antd";
import React, { useContext } from "react";
import { FaUtensils, FaChair } from "react-icons/fa";
import { GiShoppingBag, GiTicket } from "react-icons/gi";
import "../styles/StadiumCard.css";
import { useNavigate } from "react-router-dom";
import { encodeParamString } from "../const/const_functions";
const { Meta } = Card;

const { Text } = Typography;

const StadiumCard = ({ stadium }) => {
  const { theme } = useContext(ConfigProvider.ConfigContext);
  const navigate = useNavigate();
  const showBusinessOfferings = (stadium) => {
    const { name, _id, stadTypes } = stadium;
    const stadiumNameDashes = name.replaceAll(" ", "-");
    let encodedStoreId = encodeParamString(_id);

    let newStoreUrl = `/order/${stadiumNameDashes}?glb=${encodedStoreId}`;
    if (stadTypes?.includes("STT_INVOICE")) {
      newStoreUrl = `/${encodedStoreId}/invoice`;
    }

    navigate(newStoreUrl, {
      state: {
        stadium: stadium,
      },
    });
  };

  const {
    dDisplayKM,
    name: businessName,
    imageUrl,
    stadiumFeaturedImage,
    cardDisplayString,
    stadTypes,
    dDisplayPrice,
    nowDoPickup,
    nowDoDelivery,
    nowDoInHouse,
    doesDoPickup,
    doesDoDelivery,
    doesDoInHouse,
  } = stadium;

  // Open store for inhouse and pickup. Cause we have clients who are pickup only.
  const isStoreOpen = nowDoInHouse || nowDoPickup;

  return (
    <Card
      onClick={() => {
        if (isStoreOpen) {
          showBusinessOfferings(stadium);
        }
      }}
      title={
        <Row
          style={{
            marginTop: 12,
            marginBottom: 12,
            height: 50,
            alignItems: "center",
          }}
        >
          {businessName ? (
            <Col span={24} style={{ lineHeight: 1, paddingRight: 10 }}>
              <Text ellipsis style={{ fontSize: 16 }}>
                {businessName}
              </Text>
            </Col>
          ) : null}
          {cardDisplayString ? (
            <Col span={24} style={{ lineHeight: 1 }}>
              <Text ellipsis style={{ fontSize: 12, color: "gray" }}>
                {cardDisplayString}
              </Text>
            </Col>
          ) : null}
        </Row>
      }
      description="This is the description"
      extra={
        <>
          {isStoreOpen ? (
            <a style={{ fontSize: 16, color: theme.token.colorSecondary }}>
              Open Now
            </a>
          ) : null}
        </>
      }
      style={{
        marginBottom: 20,
        cursor: isStoreOpen ? "pointer" : "default",
      }}
      bodyStyle={{ padding: 0, height: 0, width: "100%" }}
      cover={
        isStoreOpen ? (
          <img
            alt="example"
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: 6,
              borderBottomRightRadius: 6,
              height: 150,
              objectFit: "cover",
              borderWidth: 1,
              borderColor: "#F2F2F2",
              borderStyle: "solid",
              display: "flex",
              width: "-webkit-fill-available",
              borderTopWidth: 0,
            }}
            src={stadiumFeaturedImage}
          />
        ) : (
          <div style={{ position: "relative", width: "100%", height: 150 }}>
            <img
              alt="example"
              style={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: 6,
                borderBottomRightRadius: 6,
                height: 150,
                objectFit: "cover",
                width: "100%",
              }}
              src={stadiumFeaturedImage}
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0,0,0,0.4)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottomLeftRadius: 6,
                borderBottomRightRadius: 6,
              }}
            >
              <div style={{ color: "white", fontSize: 18, fontWeight: "bold" }}>
                Closed
              </div>
            </div>
          </div>
        )
      }
    ></Card>
  );
};

export default StadiumCard;
