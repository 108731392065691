import simpleDDP from "simpleddp"; // ES6
import { simpleDDPLogin } from "simpleddp-plugin-login";
import ws from "isomorphic-ws";
import moment from "moment-timezone";
import { message, notification } from "antd";
import { getLatLng } from "react-google-places-autocomplete";
import tinycolor from "tinycolor2";

export const screenWidths = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

export const convertToStandardPhoneNumber = (phoneNumber) => {
  // Remove all non-numeric characters from the phone number string
  const numericPhoneNumber = phoneNumber.replace(/\D/g, "");

  // Extract country code
  let countryCode = "";
  let standardPhoneNumber = numericPhoneNumber;
  if (numericPhoneNumber.startsWith("+")) {
    const countryCodeEndIndex = numericPhoneNumber.indexOf("(");
    if (countryCodeEndIndex !== -1) {
      countryCode = numericPhoneNumber.substring(1, countryCodeEndIndex);
      standardPhoneNumber = numericPhoneNumber.substring(
        countryCodeEndIndex + 1
      );
    } else {
      countryCode = numericPhoneNumber.substring(1, 3);
      standardPhoneNumber = numericPhoneNumber.substring(3);
    }
  }

  // If the number has an area code, extract it
  if (standardPhoneNumber.length > 10) {
    standardPhoneNumber = standardPhoneNumber.slice(-10);
  }

  return standardPhoneNumber;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const encodeParamString = (paramString) => {
  // These are alternative ways to generate base64 strings
  // btoa is deprecated
  // const base64String = btoa(paramString);
  // Buffer might not be defined in react.js codebase
  // const base64String = Buffer.from(paramString).toString('Base64');
  // Btoa is available from the browser side environment so this is most likely to work.
  const base64String = window.btoa(paramString);
  const encodedString = encodeURIComponent(base64String);
  return encodedString;
};

export const decodeParamString = (encodedParam) => {
  const base64String = decodeURIComponent(encodedParam);
  // Depends on btoa method used in encodeParamString.
  // If you change the way you convert string to base64
  // then you will have to change how you convert base64 back to string
  // atob is deprecated
  //const decodedString = atob(base64String);
  // Buffer might not be defined in react.js codebase
  //const decodedString = Buffer.from(base64String, 'base64').toString('utf8');
  // Atob is available from the browser side environment so this is most likely to work.
  const decodedString = window.atob(base64String);
  return decodedString;
};

export const connectToServer = async (serverWebSocket) => {
  // Connect to ddp server
  // Options to connect to our server
  let opts = {
    endpoint: serverWebSocket,
    SocketConstructor: ws,
    reconnectInterval: 5000,
  };

  const server = new simpleDDP(opts, [simpleDDPLogin]);

  await server.connect();

  // Connection is now ready
  // console.log("Connected to Meteor Server");
  return server;
};

export const loginWithToken = async (server, token) => {
  try {
    const userAuth = await server.login({ resume: token });
    localStorage.removeItem("guestAddress");
    return userAuth;
  } catch (e) {
    // There was an issue with the token that was stored in this users localstorage
    // Remove the token
    localStorage.removeItem("token");

    return null;
  }
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePassword = (password) => {
  return (
    typeof password === "string" && password.trim() && password.length >= 6
  );
};

export const clearAllLocalStorage = () => {
  localStorage.removeItem("guestAddress");
  localStorage.removeItem("token");
  localStorage.removeItem("multiCart");
  localStorage.removeItem("region");
  const messageContent = {
    message: "Local storage cleared",
    description:
      "We have cleared the localstorage, it is best to now refresh the page.",
    placement: "bottom",
  };
  infoNotification(messageContent);
};

export const infoNotification = (messageContent) => {
  const { message, description, placement } = messageContent;
  notification.info({
    message,
    description,
    placement,
  });
};

export const formatXDay = (ts) => {
  return moment(ts).format("MMM Do YYYY");
};

export const formatXTime = (ts) => {
  return moment(ts).format("h:mmA");
};

export const mom4tzDateOnly = (epoch_ms, tz) => {
  // epoch ms is returned by Date.now()
  // you can add minutes to it by adding:
  // Date.now() + (N min * 60000 ms/min)

  return moment.utc(epoch_ms).tz(tz).format("dddd, MMMM Do YYYY");
};

export const mom4tzTimeOnly = (epoch_ms, tz) => {
  // epoch ms is returned by Date.now()
  // you can add minutes to it by adding:
  // Date.now() + (N min * 60000 ms/min)

  return moment.utc(epoch_ms).tz(tz).format("h:mm a");
};

export const removeSecondColon = (str) => {
  if (!str) {
    return null;
  }
  const index = str.lastIndexOf(":");
  return str.slice(0, index) + str.slice(index + 1);
};

export const calculateRetailItemTotal = (merchItemArray) => {
  let totalPrice = 0;

  for (let merchItem of merchItemArray) {
    const { quantityToPurchase, price } = merchItem;
    totalPrice += quantityToPurchase * price;
  }

  return totalPrice;
};

export const calculateVenueItemTotal = (foodItemArray, venueItemArray) => {
  let totalPrice = 0;

  if (foodItemArray) {
    for (let foodItem of foodItemArray) {
      let costOfAddOns = 0;
      for (let optionGroup of foodItem.optionGroups) {
        for (let zOption of optionGroup.zOptions) {
          if (zOption.isSelected === true) {
            costOfAddOns += zOption.optionObjExtraCost;
          }
        }
      }
      totalPrice +=
        (foodItem.price + costOfAddOns) * foodItem.quantityToPurchase;
    }
  }

  if (venueItemArray) {
    for (let eventItems of venueItemArray) {
      for (let admisType of eventItems.admissionPurchaseQuantities) {
        totalPrice += admisType.evAdmisBasePrice * admisType.quantityToPurchase;
      }
    }
  }

  return totalPrice;
};

export const calculateFoodItemTotal = (foodItemArray) => {
  let totalPrice = 0;
  for (let foodItem of foodItemArray) {
    let costOfAddOns = 0;
    for (let optionGroup of foodItem.optionGroups) {
      for (let zOption of optionGroup.zOptions) {
        if (zOption.isSelected === true) {
          costOfAddOns += zOption.optionObjExtraCost;
        }
      }
    }
    totalPrice += (foodItem.price + costOfAddOns) * foodItem.quantityToPurchase;
  }

  return totalPrice;
};
export const mom4tzDateAndTime = (epoch_ms, tz) => {
  // epoch ms is returned by Date.now()
  // you can add minutes to it by adding:
  // Date.now() + (N min * 60000 ms/min)

  return moment.utc(epoch_ms).tz(tz).format("dddd, MMMM Do YYYY - h:mm a");
};

export const momEventStartTime = (epoch_ms, tz) => {
  const day = moment.utc(epoch_ms).tz(tz).format("DD");
  const month = moment.utc(epoch_ms).tz(tz).format("MMM").toUpperCase();
  const year = moment.utc(epoch_ms).tz(tz).format("YYYY");
  const time = moment.utc(epoch_ms).tz(tz).format("h:mm A");
  return { day, month, year, time };
};

export const validateCanadianPostalCode = (postalCode) => {
  const postalCodePattern = /^[A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d$/;
  return postalCodePattern.test(postalCode);
};

export const rfx_v2_xnew_transformOrderObject = (src_order) => {
  let {
    deliveryOption,
    stadium: { _id, stadTypes },
    address,
    note,
    deliveryComment,
    paymentInfo: { platform, appVersion },
    zone,
    tableNumber,
    clientPickup,
    invoicesToPay,
  } = src_order;

  if (stadTypes.includes("STT_RESTO")) {
    let foodsOrdered = [];

    for (let foodItem of src_order.foodItems.foodItems) {
      let foodItemTransform = {};
      foodItemTransform.foodItemId = foodItem.foodItemId;
      foodItemTransform.quantity = foodItem.quantityToPurchase;
      foodItemTransform.foodItemOptions = [];

      if (!foodItem.optionGroups) {
        foodsOrdered.push(foodItem);
        continue;
      }

      for (let src_optionGroup of foodItem.optionGroups) {
        let optionGroup = {};
        optionGroup.optionGroupId = src_optionGroup.optionGroupId;
        optionGroup.optionsSelected = [];

        for (let src_option of src_optionGroup.zOptions) {
          // If option is selected then add.
          if (src_option.isSelected) {
            optionGroup.optionsSelected.push(src_option.optionObjId);
          }
        }
        foodItemTransform.foodItemOptions.push(optionGroup);
      }
      foodsOrdered.push(foodItemTransform);
    }

    let dest_order = {
      deliveryOption,
      address: address ? address : "N/A",
      platformInfo: { platform, appVersion },
      note: note ? note : "N/A",
      deliveryNote: deliveryComment ? deliveryComment : "N/A",
      stadiumId: _id,
      foodsOrdered,
      zone,
      tableNumber: tableNumber && !clientPickup ? tableNumber : "",
      clientPickup,
    };
    return dest_order;
  } else if (stadTypes.includes("STT_RETAIL")) {
    const merchItemsTransformed = [];
    for (const merchItem of src_order.foodItems.merchItems) {
      let merchToAdd = {};
      merchToAdd.retailItemId = merchItem.retailItemId;
      merchToAdd.quantityToPurchase = merchItem.quantityToPurchase;
      if (merchItem.isSimpleProduct) {
        merchToAdd.buySimple = true;
      } else {
        merchToAdd.buySimple = false;
        merchToAdd.veepToBuy = merchItem.veepMapKey;
      }
      merchItemsTransformed.push(merchToAdd);
    }

    let dest_order = {
      deliveryOption,
      address: address ? address : "N/A",
      platformInfo: { platform, appVersion },
      note: note ? note : "N/A",
      deliveryNote: deliveryComment ? deliveryComment : "N/A",
      stadiumId: _id,
      zone,
      merchItems: merchItemsTransformed,
      tableNumber: tableNumber && !clientPickup ? tableNumber : "",
      clientPickup,
    };

    return dest_order;
  } else if (stadTypes.includes("STT_VENUE")) {
    let foodsOrdered = [];

    if (src_order.foodItems.foodItems) {
      for (let foodItem of src_order.foodItems.foodItems) {
        let foodItemTransform = {};
        foodItemTransform.foodItemId = foodItem.foodItemId;
        foodItemTransform.quantity = foodItem.quantityToPurchase;
        foodItemTransform.foodItemOptions = [];

        if (!foodItem.optionGroups) {
          foodsOrdered.push(foodItem);
          continue;
        }

        for (let src_optionGroup of foodItem.optionGroups) {
          let optionGroup = {};
          optionGroup.optionGroupId = src_optionGroup.optionGroupId;
          optionGroup.optionsSelected = [];

          for (let src_option of src_optionGroup.zOptions) {
            // If option is selected then add.
            if (src_option.isSelected) {
              optionGroup.optionsSelected.push(src_option.optionObjId);
            }
          }

          foodItemTransform.foodItemOptions.push(optionGroup);
        }
        foodsOrdered.push(foodItemTransform);
      }
    }

    let evCartItems = [];

    if (src_order.foodItems.eventItems) {
      for (let eventItem of src_order.foodItems.eventItems) {
        const moddedEventItem = {};
        moddedEventItem.evId = eventItem.eventItemId;
        moddedEventItem.admissionPurchaseQuantities = [];
        for (const admissionType of eventItem.admissionPurchaseQuantities) {
          const moddedAdmissionType = {};
          moddedAdmissionType.evAdmisId = admissionType.evAdmisId;
          moddedAdmissionType.quantityToPurchase =
            admissionType.quantityToPurchase;
          moddedEventItem.admissionPurchaseQuantities.push(moddedAdmissionType);
        }
        evCartItems.push(moddedEventItem);
      }
    }

    let dest_order = {
      deliveryOption,
      address: address ? address : "N/A",
      platformInfo: { platform, appVersion },
      note: note ? note : "N/A",
      deliveryNote: deliveryComment ? deliveryComment : "N/A",
      stadiumId: _id,
      zone,
      foodsOrdered,
      evCartItems,
      tableNumber: tableNumber && !clientPickup ? tableNumber : "",
      clientPickup,
    };

    return dest_order;
  } else if (stadTypes.includes("STT_INVOICE")) {
    let dest_order = {
      deliveryOption: "invoice",
      platformInfo: { platform, appVersion },
      note: note ? note : "N/A",
      zone,
      stadiumId: _id,
      invoicesToPay,
    };
    return dest_order;
  }
};

export const parseGoogleDetails = async (googleResult, fromTheWeb = false) => {
  let googleDetails = null;

  // Google's server-side geocoding api always returns an array of result objects, of which only the first element needs to be parsed,
  if (Array.isArray(googleResult)) {
    googleDetails = googleResult[0];
  } else {
    // Google's client-side places api always returns a result object.
    googleDetails = googleResult;
  }

  const {
    address_components,
    formatted_address: formattedAddress,
    geometry: { location: geoLoc },
    modifiedLocation,
  } = googleDetails;

  // Address components
  let postalCode = "";
  let city = "";
  let province = "";
  let country = "";

  // Coordinates
  let geoPointMongo = null;
  let geoPointGoogle = null;

  try {
    geoPointGoogle = await getLatLng(googleDetails);
  } catch (error) {
    geoPointGoogle = geoLoc;
  }

  // Sent from the web, extracted differently
  if (fromTheWeb) {
    const { lat, lng } = modifiedLocation;
    geoPointGoogle = { lat, lng };
  }

  // For display purposes
  let shortAddress = "";

  // Parsing out postal code and coordinates
  // RO_WATCH: If the address is something like "Rideau Street, Ottawa, ON" it is not specific enough for google to retrieve information
  // In such case it will not set a postal code.
  try {
    // Extract address components
    const streetComp = address_components.find((elem) => {
      return elem.types.includes("street_number");
    });
    const streetNumber =
      streetComp && streetComp.short_name ? streetComp.short_name : "";

    const routeComp = address_components.find((elem) => {
      return elem.types.includes("route");
    });
    const route = routeComp && routeComp.short_name ? routeComp.short_name : "";

    const premiseComp = address_components.find((elem) => {
      return elem.types.includes("premise");
    });
    const premise =
      premiseComp && premiseComp.short_name ? premiseComp.short_name : "";

    const neighbourhoodComp = address_components.find((elem) => {
      return elem.types.includes("neighbourhood");
    });
    const neighbourhood =
      neighbourhoodComp && neighbourhoodComp.short_name
        ? neighbourhoodComp.short_name
        : "";

    const subLocal1Comp = address_components.find((elem) => {
      return elem.types.includes("sublocality_level_1");
    });
    const subLocal1 =
      subLocal1Comp && subLocal1Comp.short_name ? subLocal1Comp.short_name : "";

    const subLocal2Comp = address_components.find((elem) => {
      return elem.types.includes("sublocality_level_2");
    });
    const subLocal2 =
      subLocal2Comp && subLocal2Comp.short_name ? subLocal2Comp.short_name : "";

    const pComp = address_components.find((elem) => {
      return elem.types.includes("postal_code");
    });
    postalCode =
      pComp && pComp.short_name ? pComp.short_name.split(" ").join("") : "";

    const lComp = address_components.find((elem) => {
      return elem.types.includes("locality");
    });
    city = lComp && lComp.short_name ? lComp.short_name : "";

    const aalv1Comp = address_components.find((elem) => {
      return elem.types.includes("administrative_area_level_1");
    });
    province = aalv1Comp && aalv1Comp.short_name ? aalv1Comp.short_name : "";

    const subComp = address_components.find((elem) => {
      return elem.types.includes("subpremise");
    });
    const subPremise = subComp && subComp.short_name ? subComp.short_name : "";

    const countryComp = address_components.find((elem) => {
      return elem.types.includes("country");
    });
    country = countryComp && countryComp.long_name ? countryComp.long_name : "";

    // shortAddress = "premise, streetNumber route, sublocality1, sublocality 2"

    // Create address strings with the required detail
    const formattedPremise = premise ? `${premise}, ` : "";
    const formattedStreetNum = streetNumber ? `${streetNumber} ` : "";
    const formattedRoute = route ? `${route}, ` : "";
    const formattedNeighbourhood =
      !route && neighbourhood ? `${neighbourhood}, ` : ""; // If route exists no need for neighbourhood
    const formattedSub1 = subLocal1 ? `${subLocal1}` : "";
    const formattedSub2 = subLocal2 ? `, ${subLocal2} ` : "";

    shortAddress =
      formattedPremise +
      formattedStreetNum +
      formattedRoute +
      formattedNeighbourhood +
      formattedSub1 +
      formattedSub2;
    shortAddress = shortAddress.trim();

    const { lat, lng } = geoPointGoogle;
    geoPointMongo = {
      type: "Point",
      coordinates: [lng, lat],
    };
  } catch (e) {
    console.log(e);
    throw new Error(
      "Geocoding response does not contain any geocoded address information"
    );
  }

  const parsedDetails = {
    formattedAddress,
    shortAddress,
    postalCode,
    city,
    province,
    country,
    geoPointMongo,
    geoPointGoogle,
  };

  return parsedDetails;
};

// export const getDeliveryCost = async (
//   server,
//   address,
//   stadiumChosenToCheckoutID
// ) => {
//   let lat = 0;
//   let lng = 0;
//   if (address.label) {
//     const results = await geocodeByAddress(address.label);
//     lat = results[0].geometry.location.lat();
//     lng = results[0].geometry.location.lng();
//   } else if (address.guestAddressLabel) {
//     lat = address.lat;
//     lng = address.lng;
//   } else {
//     lat = address.geoPointGoogle.lat;
//     lng = address.geoPointGoogle.lng;
//   }

//   const userDeliveryAddress = {
//     coords: {
//       lat,
//       lng,
//     },
//   };

//   // Call getZone to check if the address the user inputted is outside of our delivery radius
//   const hasZone = await server.call("getZone", userDeliveryAddress);
//   if (!hasZone) {
//     message.error("Address is outside of delivery radius", 5);
//     return null;
//   }

//   let coordsAndStadiumId = {
//     userCoords: {
//       lat,
//       lng,
//     },
//     stadiumId: stadiumChosenToCheckoutID,
//   };

//   const deliveryPriceAndDistance = await server.call(
//     "USSR_calculatePriceAndDistance",
//     coordsAndStadiumId
//   );

//   return deliveryPriceAndDistance.deliveryPrice;
// };

export const finalTotal = (cart, storeType) => {
  let total = 0;
  if (storeType === "food") {
    cart.foodItems.forEach((foodItem) => {
      let addOnCost = 0;
      if (foodItem.optionGroups) {
        foodItem.optionGroups.forEach((mod) => {
          mod.zOptions.forEach((option) => {
            if (option.isSelected) {
              addOnCost = addOnCost + option.optionObjExtraCost;
            }
          });
        });
        total +=
          parseFloat(foodItem.price) * parseInt(foodItem.quantityToPurchase) +
          addOnCost * parseInt(foodItem.quantityToPurchase);
      }
    });
  } else if (storeType === "retail") {
    cart.merchItems.forEach((merchItem) => {
      total +=
        parseFloat(merchItem.price) * parseInt(merchItem.quantityToPurchase);
    });
  } else if (storeType === "venue") {
    //count total for foodItems
    let foodTotal = 0;
    if (cart.foodItems) {
      cart.foodItems.forEach((foodItem) => {
        let addOnCost = 0;
        if (foodItem.optionGroups) {
          foodItem.optionGroups.forEach((mod) => {
            mod.zOptions.forEach((option) => {
              if (option.isSelected) {
                addOnCost = addOnCost + option.optionObjExtraCost;
              }
            });
          });
          foodTotal +=
            parseFloat(foodItem.price) * parseInt(foodItem.quantityToPurchase) +
            addOnCost * parseInt(foodItem.quantityToPurchase);
        }
      });
    }
    //count total for eventItems
    let eventTotal = 0;
    if (cart.eventItems) {
      cart.eventItems.forEach((item) => {
        let admTotal = 0;
        item.admissionPurchaseQuantities.forEach((admisPQ) => {
          admTotal =
            admTotal +
            parseFloat(admisPQ.evAdmisBasePrice) *
              parseFloat(admisPQ.quantityToPurchase);
        });
        eventTotal = eventTotal + admTotal;
      });
    }
    total = eventTotal + foodTotal;
  }

  const totalToDisplay = total.toFixed(2);
  return totalToDisplay;
};

export const userAccountInfoValidation = (value, fieldType) => {
  switch (fieldType) {
    case "isName":
      if (value === undefined || !isNaN(value) || value.length < 2) {
        return;
      } else {
        return true;
      }

    case "isPhone":
      let phone = new RegExp(/^\d{10}$/);
      if (isNaN(value)) {
        return;
      } else if (value.match(phone)) {
        return true;
      } else return;

    case "isPostalCode":
      if (value === undefined || !isNaN(value) || value.length !== 6) {
        return;
      } else {
        return true;
      }

    default:
      break;
  }
};

export const showThisEvent = (event) => {
  const currentDate = new Date();
  const checkDate = moment(event.evOperatingTime.evEndTime).add(1, "days");
  if (moment(currentDate).isAfter(checkDate)) {
    return false;
  }
  return true;
};

export const showThisEventCategory = (eventCategory) => {
  const currentDate = new Date();
  const result = eventCategory.EventItems.every((event) => {
    const checkDate = moment(event.evOperatingTime.evEndTime).add(1, "days");
    return moment(currentDate).isAfter(checkDate);
  });

  if (result) return true;
  if (!result) return false;
};
export const containsNumber = (string) => {
  return /\d/.test(string);
};

export const containsLetters = (string) => {
  return (
    /[a-z]/.test(string) ||
    /[A-Z]/.test(string) ||
    /[!`~@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(string)
  );
};

export const containsUpperCaseLetter = (string) => {
  return /[A-Z]/.test(string);
};

export const randomizeArray = (passedArray) => {
  let retArray = passedArray;
  for (let i = passedArray.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let temp = retArray[i];
    retArray[i] = retArray[j];
    retArray[j] = temp;
  }
  return retArray;
};

export const removeThisCart = (cart) => {
  const currentDate = new Date();
  const checkDate = moment(cart.createdAt).add(1, "days");
  if (!cart.createdAt || moment(currentDate).isAfter(checkDate)) {
    return true;
  } else {
    return false;
  }
};

export const isLocationRemovabele = (createdAt) => {
  const currentDate = new Date();
  const checkDate = moment(createdAt).add(7, "days");
  if (moment(currentDate).isAfter(checkDate)) {
    return true;
  } else {
    return false;
  }
};

export const getItemCount = (cart) => {
  let quantity = 0;
  if (!cart) return quantity;
  if (cart.stadTypes.includes("STT_RESTO")) {
    quantity = cart.foodItems.reduce(
      (p, { quantityToPurchase }) => p + quantityToPurchase,
      0
    );
  } else if (cart.stadTypes.includes("STT_VENUE")) {
    let evQuantity = 0;
    if (cart.eventItems) {
      cart.eventItems.forEach((item) => {
        let admQuantity = 0;
        admQuantity = item.admissionPurchaseQuantities.reduce(
          (p, { quantityToPurchase }) => p + quantityToPurchase,
          0
        );
        evQuantity = evQuantity + admQuantity;
      });
    }
    let foodQuantity = 0;
    if (cart.foodItems) {
      foodQuantity = cart.foodItems.reduce(
        (p, { quantityToPurchase }) => p + quantityToPurchase,
        0
      );
    }
    quantity = foodQuantity + evQuantity;
  } else if (cart.stadTypes.includes("STT_RETAIL")) {
    quantity = cart.merchItems.reduce(
      (p, { quantityToPurchase }) => p + quantityToPurchase,
      0
    );
  }

  return quantity;
};

export const generateShades = (passedColor) => ({
  10: tinycolor(passedColor).lighten(40).toHexString(),
  50: tinycolor(passedColor).lighten(30).toHexString(),
  100: tinycolor(passedColor).lighten(20).toHexString(),
  200: tinycolor(passedColor).lighten(10).toHexString(),
  300: tinycolor(passedColor).lighten(5).toHexString(),
  400: tinycolor(passedColor).darken(5).toHexString(),
  500: passedColor,
  600: tinycolor(passedColor).darken(10).toHexString(),
  700: tinycolor(passedColor).darken(20).toHexString(),
  800: tinycolor(passedColor).darken(30).toHexString(),
  900: tinycolor(passedColor).darken(50).toHexString(),
});

export const getFeaturedItems = async (stadiumId, serverData) => {
  const res = await serverData.call("USSR_getStoreFeaturedItems", {
    stadiumId: stadiumId,
  });
  const { featuredItems } = res;
  return featuredItems;
};

export const getTimeOperation = (stadium) => {
  const {
    nowDoDelivery,
    nowDoPickup,
    nowDoInHouse,
    pickupWindowForDay,
    inhouseWindowForDay,
    deliveryWindowForDay,
  } = stadium;

  let windowForDay = pickupWindowForDay;
  if (nowDoInHouse && !nowDoPickup && !nowDoDelivery) {
    windowForDay = inhouseWindowForDay;
  } else if (!nowDoInHouse && !nowDoPickup && nowDoDelivery) {
    windowForDay = deliveryWindowForDay;
  }

  let isStadiumOpen = true;
  if (!nowDoDelivery && !nowDoPickup && !nowDoInHouse) {
    isStadiumOpen = false;
  }

  let timeOperation = isStadiumOpen ? "OPEN" : "CLOSED";
  if (isStadiumOpen && windowForDay?.close) {
    const timeWithoutSecondColon = removeSecondColon(windowForDay.close);
    const timeOFDay = timeWithoutSecondColon.slice(-2).toUpperCase();
    const formatedTime = timeWithoutSecondColon.slice(0, -2) + timeOFDay;
    timeOperation = `OPEN - Closes ${formatedTime}`;
  } else if (!isStadiumOpen && windowForDay?.open) {
    const timeWithoutSecondColon = removeSecondColon(windowForDay.open);
    const timeOFDay = timeWithoutSecondColon.slice(-2).toUpperCase();
    const formatedTime = timeWithoutSecondColon.slice(0, -2) + timeOFDay;
    timeOperation = `CLOSED - Opens ${formatedTime}`;
  }
  return timeOperation;
};

export const isQuantitiesWithinMax = (cart) => {
  const maxQuantityItems = {};
  cart?.eventItems?.forEach((event) =>
    event?.admissionPurchaseQuantities?.forEach((item) => {
      const { evAdmisId, evAdmisCustMax, evAdmisName, quantityToPurchase } =
        item;
      if (evAdmisCustMax) {
        maxQuantityItems[evAdmisId] ??= {
          quantityToPurchase: 0,
          evAdmisCustMax,
          evAdmisName,
        };
        maxQuantityItems[evAdmisId].quantityToPurchase += quantityToPurchase;
      }
    })
  );
  const notWithinMax = Object.values(maxQuantityItems).find(
    ({ quantityToPurchase, evAdmisCustMax }) =>
      quantityToPurchase > evAdmisCustMax
  );
  return notWithinMax ? notWithinMax : null;
};

export const formatPrice = (price) => {
  const priceString = `$${Number(price).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
  return priceString;
};

export const roundTo3DecimalPlaces = (number) => {
  let numStr = number.toString();

  // Check if the number has a decimal part
  if (numStr.includes(".")) {
    // Split the number into integer and decimal parts
    let [integerPart, decimalPart] = numStr.split(".");

    // Check if the decimal part has more than 3 digits
    if (decimalPart.length > 3) {
      // Round the decimal part to 3 decimal places
      decimalPart = parseFloat(`0.${decimalPart}`).toFixed(3).substring(2);
    }

    // Concatenate the integer and decimal parts
    numStr = `${integerPart}.${decimalPart}`;
  }
  return numStr;
};
