import React, { useContext } from "react";
import { Button, Col, Row, Typography, ConfigProvider } from "antd";
import "../styles/StadiumBanner.css";
import { useCarts } from "../contexts/Carts";
import { useNavigate } from "react-router-dom";
import { getItemCount } from "../const/const_functions";

const { Text } = Typography;

const StadiumBanner = ({ stadium, cartFromThisStadium, mobileSize }) => {
  const { stadiumFeaturedImage, address, name } = stadium;
  const { multiCart } = useCarts();
  const navigate = useNavigate();
  const { theme } = useContext(ConfigProvider.ConfigContext);

  const foundCart = multiCart.find((cart) => cart.stadiumID === stadium._id);
  const itemCount = getItemCount(foundCart);

  return (
    <div
      style={{
        backgroundImage: `url(${stadiumFeaturedImage})`,
        marginTop: -1,
      }}
      className="main"
    >
      <div className="container">
        <div className="column">
          <Row align="middle">
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 16, offset: 4 }}>
              <Row justify={"space-between"} align={"middle"}>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 18 }}
                  style={{ paddingRight: 12 }}
                >
                  <Row>
                    <Text strong ellipsis className="textName">
                      {name}
                    </Text>
                  </Row>
                  <Row>
                    <Text ellipsis className="textAddress">
                      {address}
                    </Text>
                  </Row>
                </Col>
                {!mobileSize && cartFromThisStadium() ? (
                  <Col span={6} style={{ textAlign: "right" }}>
                    <Button
                      style={{
                        backgroundColor: theme.token.colorPrimary,
                        color: theme.token.colorTertiary,
                      }}
                      size="large"
                      onClick={() => {
                        const foundCart = multiCart.find(
                          (cart) => cart.stadiumID === stadium._id
                        );
                        navigate(
                          `/checkout/${foundCart.stadiumName.replaceAll(
                            " ",
                            "-"
                          )}?glb=${foundCart.stadiumID}`,
                          {
                            state: {
                              cart: foundCart,
                            },
                          }
                        );
                      }}
                    >
                      Checkout - {itemCount}{" "}
                      {itemCount === 1 ? "item" : "items"}
                    </Button>
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default StadiumBanner;
