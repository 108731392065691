import React, { useState, useContext } from "react";
import {
  Typography,
  Row,
  Col,
  Button,
  Avatar,
  ConfigProvider,
  message,
  Image,
} from "antd";
import { CgMathPlus, CgMathMinus } from "react-icons/cg";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { formatPrice, screenWidths } from "../const/const_functions";
import styles from "../styles/RetailItemModal.module.css";
import { useCarts } from "../contexts/Carts";

const { Text } = Typography;

const SimpleProductCard = ({
  setShowRetailItemModal,
  currentItem,
  shopType,
  stadium,
}) => {
  const { theme } = useContext(ConfigProvider.ConfigContext);
  const { multiCart, setMultiCartData } = useCarts();
  const { width } = useWindowDimensions();
  const { Ptitle, DefaultImages, simpleProductData, Description, SkuHandle } =
    currentItem;
  const [startQuantity, setStartQuantity] = useState(1);
  const quantityFlag = startQuantity > 1;

  let imgUrl = "";
  if (typeof DefaultImages[0] === "string" && DefaultImages[0].trim() !== "") {
    imgUrl = DefaultImages[0];
  }
  const price = (Number(simpleProductData.smpPriceCents) / 100).toFixed(2);
  let mySaneDesc = "";
  if (typeof Description === "string" && Description.trim() !== "") {
    mySaneDesc = Description.trim();
  }

  const isSoldOut = simpleProductData.smpQuantity ? false : true;

  const onClickPlus = () => {
    if (startQuantity < simpleProductData.smpQuantity) {
      const newQuantity = startQuantity + 1;
      setStartQuantity(newQuantity);
    }
  };

  const onClickMinus = () => {
    if (startQuantity > 1) {
      const newQuantity = startQuantity - 1;
      setStartQuantity(newQuantity);
    }
  };

  const createNewCart = (time, currentDate) => {
    const createdCart = {};
    createdCart.stadiumID = stadium._id;
    createdCart.stadiumName = stadium.name;
    createdCart.stadiumAddress = stadium.address;
    createdCart.stadiumLogoUrl = stadium.imageUrl;
    createdCart.stadiumFeaturedImage = stadium.stadiumFeaturedImage;
    createdCart.stadiumIsOpen = stadium.isOpen;
    createdCart.stadTypes = stadium.stadTypes;
    createdCart.stadiumHomeRegion = stadium.homeRegion;
    createdCart.createdAt = currentDate;

    // Push the current retail item onto the current cart
    createdCart.merchItems = [];
    createdCart.merchItems.push({
      apfRenderId: time,
      retailItemId: currentItem._id,
      SkuHandle: SkuHandle,
      price: Number(simpleProductData.smpPriceCents) / 100,
      quantityToPurchase: startQuantity,
      quantityAvailable: simpleProductData.smpQuantity,
      pTitle: Ptitle,
      image: DefaultImages[0],
      isSimpleProduct: true,
    });
    return createdCart;
  };
  const addRetailItemToCart = () => {
    // Used in order to make the items unique when added to the cart
    // Some cases the user may go back and add the same item.
    // in this case, we cannot use the item's 'id'
    const d = new Date();
    const time = d.getTime();

    // Boolean to check if there is a cart in the multicart with the same stadiumid
    // This boolean determines if we are going to have to create a new cart, or add
    // a new cart to the multicart
    let sameStadiumObj = multiCart.find(
      (cart) => cart.stadiumID === stadium._id
    );
    if (sameStadiumObj) {
      // The user is adding another item from a stadium that is already in his carts array
      if (!sameStadiumObj.merchItems) sameStadiumObj.merchItems = [];
      const newRetailItem = {
        // Special id used for rendering the same item twice (frontend only)
        apfRenderId: time,
        retailItemId: currentItem._id,
        SkuHandle: SkuHandle,
        price: Number(simpleProductData.smpPriceCents) / 100,
        quantityToPurchase: startQuantity,
        quantityAvailable: simpleProductData.smpQuantity,
        pTitle: Ptitle,
        image: DefaultImages[0],
        isSimpleProduct: true,
      };

      sameStadiumObj.merchItems = [...sameStadiumObj.merchItems, newRetailItem];
      const newCartItems = multiCart.map((cart) =>
        cart.stadiumID === stadium._id ? sameStadiumObj : cart
      );
      setMultiCartData(newCartItems);
    } else {
      const createdCart = createNewCart(time, d);
      setMultiCartData([...multiCart, createdCart]);
    }
    setShowRetailItemModal(false);
    setStartQuantity(1);
    message.open({
      type: "success",
      content: `${startQuantity} x ${Ptitle} ${
        startQuantity > 1 ? "have" : " has"
      } been added to your cart`,
    });
  };

  return (
    <>
      <Text className={styles.name}>{shopType}</Text>
      <Row
        className={styles.titleRow}
        justify={width > screenWidths.md ? "start " : "center"}
        style={width < screenWidths.sm ? { alignItems: "center" } : null}
      >
        <Col
          xs={{ span: 18 }}
          sm={{ span: 6 }}
          style={
            width < screenWidths.sm ? { marginBottom: 22 } : { marginBottom: 0 }
          }
        >
          {imgUrl && <Image src={imgUrl} className={styles.retailImg} />}
        </Col>

        <Col xs={{ span: 24 }} sm={{ span: 18 }} className={styles.titleColumn}>
          <Row>
            <Col span={24}>
              <Text className={styles.name}>{Ptitle}</Text>
            </Col>
            <Col span={24}>
              <Text className={styles.textDesc}>{mySaneDesc}</Text>
            </Col>
          </Row>
          <Row style={{ paddingTop: 20 }}>
            <Text className={styles.price}>{formatPrice(Number(price))}</Text>
            <Text style={{ paddingTop: "2px" }}>/ each</Text>
          </Row>
        </Col>
      </Row>

      <Row className={styles.rowCenter}>
        <Col
          className={styles.quantityCol}
          xs={{ span: 22, offset: 1 }}
          md={{ span: 16, offset: 4 }}
        >
          <Button
            style={{
              borderColor: quantityFlag
                ? theme.token.colorPrimary
                : "lightgray",
              boxShadow: "2px 3px 4px #ccc",
            }}
            className={styles.quantityButtonMinus}
            disabled={!quantityFlag}
            onClick={() => onClickMinus()}
          >
            <CgMathMinus className={styles.quantityIcon} />
          </Button>
          <Text className={styles.quantityText}>{startQuantity}</Text>
          <Button
            disabled={
              startQuantity < simpleProductData.smpQuantity ? false : true
            }
            style={{
              boxShadow: "2px 3px 4px #ccc",
              borderColor:
                startQuantity < simpleProductData.smpQuantity
                  ? theme.token.colorPrimary
                  : "lightgray",
            }}
            className={styles.quantityButton}
            onClick={() => onClickPlus()}
          >
            <CgMathPlus className={styles.quantityIcon} />
          </Button>
        </Col>
      </Row>
      <Row className={styles.rowCenter}>
        <Col
          className={styles.quantityCol}
          xs={{ span: 24 }}
          md={{ span: 16, offset: 4 }}
        >
          {isSoldOut ? (
            <Button
              style={{
                backgroundColor: theme.token.colorSecondary,
                color: "white",
                marginBottom: 30,
              }}
              className={styles.addVariantButton}
            >
              Out of Stock
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: theme.token.colorSecondary,
                marginBottom: 30,
              }}
              disabled={isSoldOut}
              className={styles.addSimpleButton}
              onClick={() => addRetailItemToCart()}
            >
              Add to cart - {formatPrice(Number(price) * Number(startQuantity))}
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

export default SimpleProductCard;
