import React, { useState, useContext } from "react";
import {
  Row,
  Col,
  Image,
  ConfigProvider,
  Typography,
  Drawer,
  Button,
} from "antd";
import { useBrandContext } from "../../../contexts/Brand";
import { useChain } from "../../../contexts/Chain";
import {
  FaReceipt,
  FaDonate,
  FaInfoCircle,
  FaQuestionCircle,
} from "react-icons/fa";
import { AiOutlineMenu, AiFillHome } from "react-icons/ai";
import { IoMdCart } from "react-icons/io";
import { GrAnnounce } from "react-icons/gr";
import { useNavigate, useLocation } from "react-router-dom";
import AnnouncementDrawer from "../../AnnouncementDrawer";
import DonateDrawer from "../../DonateDrawer";
import TrackOrderModal from "../../TrackOrderModal";
import styles from "../../../styles/HeaderVar1.module.css";

const HeaderVar1 = ({ executeScroll }) => {
  const navigate = useNavigate();
  const { Text, Link } = Typography;
  const { theme } = useContext(ConfigProvider.ConfigContext);
  const { brandContextData, loadingBrandContextData } = useBrandContext();
  const { featuredItems, locationSelected } = useChain();
  const location = useLocation();

  const [showHeaderDrawer, setShowHeaderDrawer] = useState(false);
  const [showAnnouncementDrawer, setShowAnnouncementDrawer] = useState(false);
  const [showDonateDrawer, setShowDonateDrawer] = useState(false);
  const [showTrackOrderModal, setShowTrackOrderModal] = useState(false);

  // If we are in the context of getit payments, do not show the header on these pages
  if (
    (location.pathname.includes("/order") ||
      location.pathname.includes("/checkout") ||
      location.pathname.includes("/pay") ||
      location.pathname.includes("/myorders")) &&
    brandContextData._id === "a5121e36-getit-technologies"
  ) {
    return null;
  }

  const _renderSocialIcon = (passedSocialLink, passedIndex) => {
    if (!passedSocialLink.iconName || !passedSocialLink.navUrl) return null;
    const iconColourStyle = brandContextData.brandTheme.colorPrimary
      ? brandContextData.brandTheme.colorPrimary
      : "black";

    const iconLink = passedSocialLink.iconName;
    const iconSize = "2em";
    return (
      <Col key={passedIndex}>
        <Link
          href={passedSocialLink.navUrl}
          target={"blank"}
          style={{ color: iconColourStyle }}
        >
          <img style={{ width: iconSize, height: iconSize }} src={iconLink} />
        </Link>
      </Col>
    );
  };

  const { ghostEventStores, stadiumListing } = brandContextData;
  let stadiums = [];
  if (!brandContextData.isChain && stadiumListing?.length) {
    stadiums = stadiumListing.filter(
      (fStadium) =>
        fStadium._id !== "k8dfXum85newkBBE4" &&
        fStadium._id !== "GYT54hH3dxCQ7PNSy"
    );
    if (ghostEventStores) {
      stadiums = stadiums.filter(
        (fStadium) => !fStadium.stadTypes.includes("STT_VENUE")
      );
    }
  }

  let customLogoWidth = 110;

  if (
    brandContextData._id === "65a7f4cab8d346fb4ac4671c" ||
    brandContextData._id === "65a9429aa534359159e0b348"
  ) {
    customLogoWidth = 200;
  }

  return (
    <Col
      style={{
        backgroundColor: theme.token.colorPrimary,
        padding: 15,
      }}
    >
      <Row justify={"space-between"}>
        <Row align={"middle"}>
          <AiOutlineMenu
            onClick={() => setShowHeaderDrawer(!showHeaderDrawer)}
            style={{
              fontSize: 24,
              color: theme.token.colorTertiary,
              paddingRight: 10,
              cursor: "pointer",
            }}
          />
          {!loadingBrandContextData && brandContextData.brandLogo ? (
            <Image
              onClick={() => navigate("/")}
              width={customLogoWidth}
              preview={false}
              src={brandContextData.brandLogo}
              style={{
                maxHeight: 100,
                objectFit: "contain",
              }}
            />
          ) : null}
        </Row>
        <Row
          align={"middle"}
          style={{
            cursor: "pointer",
          }}
          onClick={() => navigate("/cart")}
        >
          <Button
            style={{
              height: 50,
              width: 50,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 0,
              backgroundColor: "white",
              borderColor: theme.token.colorTertiary,
              borderRadius: 50,
            }}
          >
            <IoMdCart
              style={{
                color: theme.token.colorSecondary,
                fontSize: 24,
              }}
            />
          </Button>
        </Row>
      </Row>
      <Drawer
        placement="left"
        onClose={() => setShowHeaderDrawer(!showHeaderDrawer)}
        open={showHeaderDrawer}
        width={"80%"}
      >
        {!loadingBrandContextData && brandContextData?.showAboutButton ? (
          <Row
            style={{ cursor: "pointer", paddingBottom: 30 }}
            align={"middle"}
            onClick={() => {
              setShowHeaderDrawer(false);
              navigate("/about-us");
            }}
          >
            <FaInfoCircle className={styles.icon} />
            <Text className={styles.title}>About Us</Text>
          </Row>
        ) : null}

        <Row
          style={{ cursor: "pointer", paddingBottom: 30 }}
          align={"middle"}
          onClick={() => {
            if (location.pathname === "/") {
              if (
                (!brandContextData.isChain && stadiums.length) ||
                (brandContextData.isChain &&
                  featuredItems?.length &&
                  locationSelected)
              ) {
                executeScroll();
              }
              setShowHeaderDrawer(false);
            } else {
              navigate("/");
              setShowHeaderDrawer(false);
            }
          }}
        >
          <AiFillHome className={styles.icon} />
          <Text className={styles.title}>Order Now</Text>
        </Row>
        <Row
          style={{ cursor: "pointer", paddingBottom: 30 }}
          align={"middle"}
          onClick={() => {
            setShowHeaderDrawer(false);
            setShowTrackOrderModal(true);
          }}
        >
          <FaReceipt className={styles.icon} />
          <Text className={styles.title}>View Order</Text>
        </Row>
        {!loadingBrandContextData &&
        brandContextData?.announcements?.length > 0 ? (
          <Row
            style={{ cursor: "pointer", paddingBottom: 30 }}
            align={"middle"}
            onClick={() => {
              setShowHeaderDrawer(false);
              setShowAnnouncementDrawer(!showAnnouncementDrawer);
            }}
          >
            <GrAnnounce className={styles.icon} />
            <Text className={styles.title}>Announcements</Text>
          </Row>
        ) : null}
        {!loadingBrandContextData && brandContextData.acceptsDonations ? (
          <Row
            style={{ cursor: "pointer", paddingBottom: 30 }}
            align={"middle"}
            onClick={() => {
              setShowHeaderDrawer(false);
              setShowDonateDrawer(!showDonateDrawer);
            }}
          >
            <FaDonate className={styles.icon} />
            <Text className={styles.title}>Donations</Text>
          </Row>
        ) : null}
        <Row style={{ cursor: "pointer", paddingBottom: 5 }} align={"middle"}>
          <FaQuestionCircle className={styles.icon} />
          <Text className={styles.title}>Support</Text>
        </Row>
        <Col style={{ paddingLeft: 34 }}>
          <Text>tel: 343-809-4400</Text>
        </Col>
        {/* <Col style={{ paddingLeft: 34 }}>
          <Link
            target="blank"
            href="mailto:support@example.com"
            style={{ color: "black", cursor: "pointer" }}
          >
            email: support@example.com
          </Link>
        </Col> */}
        {!loadingBrandContextData && brandContextData.brandSocial?.length ? (
          <Col style={{ bottom: 10, position: "absolute" }}>
            <Text style={{ fontWeight: "bold" }}>Let's Connect!</Text>
            <Row gutter={6} style={{ paddingTop: 5 }}>
              {brandContextData.brandSocial.map((socialLink, index) =>
                _renderSocialIcon(socialLink, index)
              )}
            </Row>
          </Col>
        ) : null}
      </Drawer>
      <AnnouncementDrawer
        showAnnouncementDrawer={showAnnouncementDrawer}
        brandContextData={brandContextData}
        setShowAnnouncementDrawer={setShowAnnouncementDrawer}
      />
      <DonateDrawer
        showDrawer={showDonateDrawer}
        brandContextData={brandContextData}
        setShowDrawer={setShowDonateDrawer}
        loadingBrandContextData={loadingBrandContextData}
        mobile={true}
      />
      <TrackOrderModal
        setShowTrackOrderModal={setShowTrackOrderModal}
        showTrackOrderModal={showTrackOrderModal}
      />
    </Col>
  );
};

export default HeaderVar1;
