import { Col, Image, Input, Layout, Row, Spin, Tooltip, message } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import SquareAddCreditCard from "../components/SquareAddCreditCard";
import { useBrandContext } from "../contexts/Brand";
import { useServer } from "../contexts/Server";
import { useNavigate, useParams } from "react-router-dom";
import {
  decodeParamString,
  screenWidths,
  validateEmail,
  validatePassword,
} from "../const/const_functions";
import useWindowDimensions from "../hooks/useWindowDimensions";
import SuccessRegistration from "../components/SuccessRegistration";
import "../styles/CreateAccount.css";
import { FaInfoCircle } from "react-icons/fa";

const CreateAccount = () => {
  const { brandContextData } = useBrandContext();
  const { serverData } = useServer();
  const [passwordError, setPasswordError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { encodedStadiumId } = useParams();
  const stadiumId = decodeParamString(encodedStadiumId);
  const [stadium, setStadium] = useState(null);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [registrationDetails, setRegistrationDetails] = useState({
    password: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (serverData) {
      getStadium();
    }
  }, [serverData]);

  const getStadium = async () => {
    let stadium;
    try {
      stadium = await serverData.call("USSR_getOneStadium", stadiumId);
    } catch (e) {
      console.log(e);
    }

    // Only show this page for invoice stores
    if (!stadium?.stadTypes?.includes("STT_INVOICE")) {
      navigate("/404");
    }

    setStadium(stadium);
  };

  const disableSaveButton = () => {
    if (Object.keys(validateRegistrationDetails()).length) {
      return true;
    }

    return false;
  };

  const createNewUser = async (token, verifiedBuyer) => {
    setLoading(true);

    const profile = {
      phone: registrationDetails?.customerPhone
        ? registrationDetails?.customerPhone
        : "",
      name: registrationDetails.customerName,
      externalAccountId: registrationDetails.accountId,
    };

    try {
      let userID = await serverData.call("BLLSTC_createPayor", {
        contextId: brandContextData._id,
        profile,
        username: registrationDetails.customerEmail.trim(),
        stadiumId,
        passwordInput: true,
        password: registrationDetails.password,
      });
      if (token && userID) {
        await getSQCustomer(token, verifiedBuyer, userID);
      }
    } catch (e) {
      console.log(e);
      if (e?.message) {
        message.error(e.message);
      } else {
        message.error(
          `Sorry, there was an error creating your account. Please try again`
        );
      }

      setLoading(false);
    }
  };

  const getSQCustomer = async (token, verifiedBuyer, userID) => {
    try {
      await serverData.call("BLLSTC_getSQCustomer", {
        userID,
      });
      createSQCustomerCard(token, verifiedBuyer, userID);
    } catch (e) {
      console.log(e);
      message.error(e.message);
      setLoading(false);
    }
  };

  const createSQCustomerCard = async (token, verifiedBuyer, userID) => {
    try {
      await serverData.call("BLLSTC_createSQCustomerCard", {
        userID,
        nonce: token.token,
        verifiedBuyer,
      });
      setSuccessModalVisible(true);
      setRegistrationDetails({ password: "" });
    } catch (e) {
      console.log(e);
      message.error(e.message);
      setLoading(false);
    }
    setLoading(false);
  };

  let mobile = false;
  const { width } = useWindowDimensions();
  if (screenWidths.md > width) {
    mobile = true;
  }

  let imgWidth = 180;

  if (
    stadium?._id === "vWbhEfxtsfbK82CKv" ||
    stadium?._id === "CakDLtXvDiB7xZRCR"
  ) {
    imgWidth = 280;
  }

  const closeSuccessModal = () => {
    setSuccessModalVisible(false);
  };

  const _renderFormField = (formObj, fieldName) => {
    const { label } = formObj;
    const formLabel = label;
    const txtLabel = (
      <p style={{ fontWeight: "600", color: "black", marginBottom: 6 }}>
        {formLabel}
        <span style={{ fontWeight: "500", color: "red", marginBottom: 6 }}>
          *
        </span>
      </p>
    );

    return (
      <>
        {txtLabel}
        <Input
          type={fieldName === "customerPhone" ? "number" : "text"}
          value={registrationDetails[fieldName]}
          onChange={(e) => takeInput({ inputVal: e.target.value, fieldName })}
        />
        <div style={{ height: 18 }}>
          {validateRegistrationDetails()[fieldName] &&
          registrationDetails[fieldName] ? (
            <p style={{ color: "red", fontSize: 12 }}>
              {validateRegistrationDetails()[fieldName]}
            </p>
          ) : null}
        </div>
      </>
    );
  };

  const validateRegistrationDetails = () => {
    const errors = {};
    if (Object.keys(registrationDetails).length === 0) {
      return { noRegistrationDetails: "There are no registration details" };
    }

    if (
      !registrationDetails.password.trim() ||
      registrationDetails.password.length < 6
    ) {
      errors["password"] = "Please enter a valid password";
    }

    Object.keys(stadium?.invoiceTemplate).forEach((field) => {
      const { type, sibling, display, label, canRegister } =
        stadium.invoiceTemplate[field];

      if (display && canRegister) {
        const value = registrationDetails[field];

        if (field === "customerEmail") {
          const trimmedValue = value?.trim();
          if (!trimmedValue || trimmedValue === "") {
            errors[field] = `${label} is required.`;
          } else {
            if (!validateEmail(trimmedValue)) {
              errors[field] = `Please enter a valid email.`;
            }
          }
        } else if (field === "customerPhone") {
          if (!value || String(value).trim() === "") {
            errors[field] = `${label} is required.`;
          } else {
            if (value.length !== 10) {
              errors[field] = `Phone numbers must be 10 digits long.`;
            }
          }
        } else {
          if (!value || String(value).trim() === "") {
            errors[field] = `${label} is required.`;
          }
        }
      }
    });

    return errors;
  };

  const takeInput = ({ inputVal, fieldName }) => {
    setRegistrationDetails((prevState) => ({
      ...prevState,
      [fieldName]: inputVal,
    }));
  };

  if (!stadium) {
    return null;
  }

  const formFields = [];
  for (const [key, value] of Object.entries(stadium?.invoiceTemplate)) {
    const newObj = { ...value, key };

    if (newObj.display && newObj.canRegister) {
      formFields.push(newObj);
    }
  }

  // Sort the formFields array based on the renderPosition property
  formFields.sort((a, b) => (a.renderPosition || 0) - (b.renderPosition || 0));

  return (
    <Layout style={{ backgroundColor: "white", minHeight: "100vh" }}>
      <SuccessRegistration
        successModalVisible={successModalVisible}
        closeSuccessModal={closeSuccessModal}
        stadiumName={stadium?.name}
      />
      <Spin
        size="large"
        spinning={loading}
        tip="Registering your account... Please do not navigate away or refresh the page."
      >
        <Content
          style={{
            marginTop: 20,
            marginBottom: 100,
            flexDirection: "column",
            display: "flex",
          }}
        >
          <Col xs={{ span: 22, offset: 1 }} md={{ span: 12, offset: 6 }}>
            {stadium?.imageUrl ? (
              <Row justify={"center"}>
                <Image
                  width={mobile ? "50%" : imgWidth}
                  preview={false}
                  src={stadium.imageUrl}
                  style={{
                    maxHeight: 180,
                    minHeight: 75,
                    objectFit: "contain",
                  }}
                />
              </Row>
            ) : null}

            <p
              style={{
                fontWeight: "600",
                color: "black",
                fontSize: 24,
                marginBottom: 0,
              }}
            >
              Register Your Account for Auto-Billing
            </p>
            <p
              style={{
                fontWeight: "500",
                color: "grey",
                marginBottom: 40,
                fontSize: 14,
                marginTop: 4,
              }}
            >
              Already Registered?{" "}
              <a href="/login">Edit/Update Your Account Information Here</a>
            </p>
            {formFields.map((field) => (
              <React.Fragment key={field.key}>
                {_renderFormField(field, field.key)}
              </React.Fragment>
            ))}
            <p style={{ fontWeight: "600", color: "black", marginBottom: 6 }}>
              Password
              <span
                style={{
                  fontWeight: "500",
                  color: "red",
                  marginBottom: 6,
                  marginRight: 4,
                }}
              >
                *
              </span>
              <Tooltip
                title={`We require a password so you may login and update your auto-billing info at a later date if necessary.`}
              >
                <FaInfoCircle
                  name="info"
                  style={{ fontSize: 15, color: "gray" }}
                />
              </Tooltip>
            </p>
            <Input
              value={registrationDetails.password}
              defaultValue={null}
              type="password"
              onChange={(e) => {
                if (validatePassword(e.target.value) || !e.target.value) {
                  takeInput({
                    inputVal: e.target.value,
                    fieldName: "password",
                  });
                  setPasswordError(false);
                } else {
                  takeInput({
                    inputVal: e.target.value,
                    fieldName: "password",
                  });
                  setPasswordError(true);
                }
              }}
              maxLength={36}
            />
            <div style={{ height: 18 }}>
              {passwordError ? (
                <p style={{ color: "red", fontSize: 12 }}>
                  Passwords must be at least 6 characters long
                </p>
              ) : null}
            </div>
            <>
              <div style={{ paddingTop: 10 }}>
                <SquareAddCreditCard
                  tempName={registrationDetails.customerName}
                  tempEmail={registrationDetails.customerEmail?.trim()}
                  tempPhone={""}
                  disableSaveButton={disableSaveButton}
                  handleSubmitCreditCard={createNewUser}
                />
              </div>
            </>
            <p style={{ fontWeight: "500", color: "grey", marginBottom: 6 }}>
              *Your credit card is saved by a secure third-party provider. Your
              personal and financial information is secure and PCI DSS compliant
              when saved through our registration portal.
            </p>
          </Col>
        </Content>
      </Spin>
    </Layout>
  );
};

export default CreateAccount;
