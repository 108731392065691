import React, { useState, useContext } from "react";
import {
  Modal,
  Typography,
  Row,
  Col,
  Button,
  message,
  Avatar,
  ConfigProvider,
} from "antd";
import { CgMathPlus, CgMathMinus } from "react-icons/cg";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { screenWidths } from "../const/const_functions";
import styles from "../styles/FoodItemModal.module.css";
import FoodItemOptions from "./FoodItemOptions";
import { useCarts } from "../contexts/Carts";
import _ from "lodash";
import { useFirebase } from "../contexts/Firebase";
import { logEvent } from "firebase/analytics";

const { Text } = Typography;

const FoodItemModal = ({
  showFoodItemModal,
  setShowFoodItemModal,
  currentItem,
  categoryItem,
  stadium,
}) => {
  const { multiCart, setMultiCartData } = useCarts();
  const { width } = useWindowDimensions();
  const { name, fXQImageURL, fXQDesc, price } = currentItem;
  const [startQuantity, setStartQuantity] = useState(1);
  const [addOnCost, setAddOnCost] = useState(0);
  const [isSelected, setIsSelected] = useState(false);
  const { theme } = useContext(ConfigProvider.ConfigContext);
  const { firebaseAnalytics } = useFirebase();

  const updateFoodModal = (passedCost) => {
    if (passedCost === addOnCost) {
      setIsSelected(!isSelected);
    } else {
      setAddOnCost(passedCost);
      setIsSelected(!isSelected);
    }
  };

  let imgUrl = "";
  if (typeof fXQImageURL === "string" && fXQImageURL.trim() !== "") {
    imgUrl = fXQImageURL;
  }

  let mySaneDesc = "";
  if (typeof fXQDesc === "string" && fXQDesc.trim() !== "") {
    mySaneDesc = fXQDesc.trim();
  }
  const isSoldOut =
    currentItem.availabilityStatus !== "unlimited" && currentItem.quantity === 0
      ? true
      : false;

  const onClickPlus = () => {
    if (currentItem.availabilityStatus === "unlimited") {
      const newQuantity = startQuantity + 1;
      setStartQuantity(newQuantity);
    } else {
      if (startQuantity < currentItem.quantity) {
        const newQuantity = startQuantity + 1;
        setStartQuantity(newQuantity);
      } else {
        message.warning(
          `Max Quantity Reached: 
          Sorry, this item has a limited quantity of ${currentItem.quantity}`,
          5
        );
      }
    }
  };

  const onClickMinus = () => {
    if (startQuantity > 1) {
      const newQuantity = startQuantity - 1;
      setStartQuantity(newQuantity);
    }
  };

  const onCancelModal = () => {
    setShowFoodItemModal(false);
    setIsSelected(false);
  };
  const createNewCart = (time, optionGroupsSelected, currentDate) => {
    const createdCart = {};
    createdCart.stadiumID = stadium._id;
    createdCart.stadiumName = stadium.name;
    createdCart.stadiumAddress = stadium.address;
    createdCart.stadiumLogoUrl = stadium.imageUrl;
    createdCart.stadiumFeaturedImage = stadium.stadiumFeaturedImage;
    createdCart.stadiumIsOpen = stadium.isOpen;
    createdCart.stadTypes = stadium.stadTypes;
    createdCart.stadiumHomeRegion = stadium.homeRegion;
    createdCart.createdAt = currentDate;

    const deepOptionGroupCopy = _.cloneDeep(optionGroupsSelected);

    // Push the current food item onto the current cart
    createdCart.foodItems = [];
    createdCart.foodItems.push({
      apfRenderId: time,
      foodItemId: currentItem._id,
      name: currentItem.name,
      price: currentItem.price,
      fXQImageURL: currentItem.fXQImageURL,
      availabilityStatus: currentItem.availabilityStatus,
      quantityAvailable: currentItem.quantity,
      quantityToPurchase: startQuantity,
      optionGroups: deepOptionGroupCopy,
      quantity: currentItem.quantity,
    });
    return createdCart;
  };

  const addFoodItemToCart = () => {
    // Used in order to make the items unique when added to the cart
    // Some cases the user may go back and add the same item.
    // in this case, we cannot use the item's 'id'
    const d = new Date();
    const time = d.getTime();

    // Build an array of option groups that the user has selected
    let optionGroupsSelected = [];
    if (currentItem.optionGroups) {
      currentItem.optionGroups.forEach((optionGroup) => {
        const hasASelectedOption = optionGroup.zOptions.some((option) => {
          return option.isSelected;
        });
        if (hasASelectedOption) {
          optionGroupsSelected.push(optionGroup);
        }
      });
    }

    // Boolean to check if there is a cart in the multicart with the same stadiumid
    // This boolean determines if we are going to have to create a new cart, or add
    // a new cart to the multicart
    let sameStadiumObj = multiCart.find(
      (cart) => cart.stadiumID === stadium._id
    );
    if (sameStadiumObj) {
      // The user is adding another item from a stadium that is already in his carts array
      if (!sameStadiumObj.foodItems) sameStadiumObj.foodItems = [];
      const deepOptionGroupCopy = _.cloneDeep(optionGroupsSelected);

      const newFoodItem = {
        // Special id used for rendering the same item twice (frontend only)
        apfRenderId: time,
        foodItemId: currentItem._id,
        name: currentItem.name,
        price: currentItem.price,
        fXQImageURL: currentItem.fXQImageURL,
        availabilityStatus: currentItem.availabilityStatus,
        quantityAvailable: currentItem.quantity,
        quantityToPurchase: startQuantity,
        optionGroups: deepOptionGroupCopy,
        quantity: currentItem.quantity,
      };

      sameStadiumObj.foodItems = [...sameStadiumObj.foodItems, newFoodItem];
      const newCartItems = multiCart.map((cart) =>
        cart.stadiumID === stadium._id ? sameStadiumObj : cart
      );
      setMultiCartData(newCartItems);
    } else {
      const createdCart = createNewCart(time, optionGroupsSelected, d);
      setMultiCartData([...multiCart, createdCart]);
    }

    if (firebaseAnalytics) {
      logEvent(firebaseAnalytics, "add_to_cart", { name: currentItem?.name });
    }

    setShowFoodItemModal(false);
    setIsSelected(false);
    setAddOnCost(0);
    setStartQuantity(1);
    message.open({
      type: "success",
      content: `${startQuantity} x ${currentItem.name} ${
        startQuantity > 1 ? "have" : " has"
      } been added to your cart`,
    });
  };
  const quantityFlag = startQuantity > 1;

  let disableAddToCart = false;
  if (currentItem.optionGroups?.length) {
    const isSelected = currentItem.optionGroups.map((optionGroup) => {
      if (optionGroup.isRequired) {
        const checkOption = optionGroup.zOptions.some(
          (option) => option.isSelected
        );
        return checkOption;
      }
      return true;
    });
    disableAddToCart = isSelected.includes(false);
  }
  return (
    <Modal
      open={showFoodItemModal}
      className={styles.modal}
      onCancel={() => onCancelModal()}
      width={width > screenWidths.md ? "60%" : "100%"}
      footer={null}
      destroyOnClose={true}
    >
      <Text className={styles.categoryName}>{categoryItem.name}</Text>
      <Row className={styles.titleRow}>
        <Col style={{ alignSelf: "center" }}>
          {imgUrl && <Avatar src={imgUrl} className={styles.img} />}
        </Col>
        <Col className={styles.titleColumn}>
          <div className={styles.descDiv}>
            <Text className={styles.name}>{name}</Text>
            <Text className={styles.textDesc}>{mySaneDesc}</Text>
          </div>
          <div>
            <Text className={styles.price}>${Number(price).toFixed(2)}</Text>
            <Text>/each</Text>
          </div>
        </Col>
      </Row>
      {currentItem.optionGroups &&
        currentItem.optionGroups.map((optionGroup, index) => {
          let isOptionGroupSelected = false;
          if (optionGroup.isRequired) {
            if (optionGroup.zOptions.filter((e) => e.isSelected).length !== 0) {
              isOptionGroupSelected = true;
            }
          }
          return (
            <Row
              key={optionGroup.optionGroupId}
              className={styles.optionGroupRow}
            >
              {currentItem.optionGroups.length !== 1 ? (
                <div className={styles.optionGroupDivider} />
              ) : null}
              <Row justify={"space-between"}>
                <Text className={styles.optionGroupName}>
                  {optionGroup.optionGroupName}
                </Text>
                {/* Put subtitle if modification is required */}
                {optionGroup.isRequired && (
                  <Text
                    style={{
                      fontSize: 12,
                      color: !isOptionGroupSelected ? "red" : "green",
                    }}
                  >
                    Required
                  </Text>
                )}
              </Row>
              {optionGroup.optionLine2 && (
                <Text className={styles.optionLine2}>
                  {optionGroup.optionLine2}
                </Text>
              )}

              <Row className={styles.requiredRow}>
                {optionGroup.isMultiSelect ? (
                  <Text style={{ fontSize: 12, color: "gray" }}>
                    Can choose many
                  </Text>
                ) : (
                  <Text style={{ fontSize: 12, color: "gray" }}>
                    Choose one
                  </Text>
                )}
              </Row>

              <FoodItemOptions
                optionGroup={optionGroup}
                updateFoodModal={updateFoodModal}
                currentItem={currentItem}
                showDivider={
                  currentItem.optionGroups?.length === 1 ? true : false
                }
              />
              {currentItem.optionGroups?.length - 1 === index &&
              currentItem.optionGroups?.length !== 1 ? (
                <div className={styles.optionGroupDivider} />
              ) : null}
            </Row>
          );
        })}

      <Row className={styles.rowCenter}>
        <Col
          className={styles.quantityCol}
          xs={{ span: 22, offset: 1 }}
          md={{ span: 16, offset: 4 }}
        >
          <Button
            style={{
              borderColor: quantityFlag
                ? theme.token.colorPrimary
                : "lightgray",
              boxShadow: "2px 3px 4px #ccc",
            }}
            className={styles.quantityButtonMinus}
            disabled={!quantityFlag}
            onClick={() => onClickMinus()}
          >
            <CgMathMinus className={styles.quantityIcon} />
          </Button>
          <Text className={styles.quantityText}>{startQuantity}</Text>
          <Button
            style={{ boxShadow: "2px 3px 4px #ccc" }}
            className={styles.quantityButton}
            onClick={() => onClickPlus()}
          >
            <CgMathPlus className={styles.quantityIcon} />
          </Button>
        </Col>
      </Row>
      <Row className={styles.rowCenter}>
        <Col
          className={styles.quantityCol}
          xs={{ span: 22, offset: 1 }}
          md={{ span: 16, offset: 4 }}
        >
          {isSoldOut ? (
            <Button className={styles.addButton}>Out of Stock</Button>
          ) : (
            <Button
              disabled={disableAddToCart}
              className={styles.addButton}
              style={{
                backgroundColor: disableAddToCart
                  ? "gray"
                  : theme.token.colorSecondary,
                color: "white",
                marginBottom: 30,
              }}
              onClick={() => addFoodItemToCart()}
            >
              Add to cart - $
              {(
                Number(price) * Number(startQuantity) +
                Number(addOnCost) * Number(startQuantity)
              ).toFixed(2)}
            </Button>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default FoodItemModal;
