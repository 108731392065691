import React, { useContext, useState } from "react";
import { Row, Col, Image, Typography, ConfigProvider, Button } from "antd";
import { screenWidths, encodeParamString } from "../const/const_functions";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useBrandContext } from "../contexts/Brand";
import { useChain } from "../contexts/Chain";
import { IoMdCart } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/WhiteLabelHeader.css";
import DonateDrawer from "./DonateDrawer";
import AnnouncementDrawer from "./AnnouncementDrawer";
import SelectLocationModal from "../components/SelectLocationModal";
const { Text } = Typography;

const WhiteLabelHeader = ({ executeScroll }) => {
  const navigate = useNavigate();
  const { theme } = useContext(ConfigProvider.ConfigContext);
  const { brandContextData, loadingBrandContextData } = useBrandContext();
  const { featuredItems, locationSelected } = useChain();

  const [showDrawer, setShowDrawer] = useState(false);
  const [showAnnouncementDrawer, setShowAnnouncementDrawer] = useState(false);
  const [showSelectLocationModal, setShowSelectLocationModal] = useState(false);
  let mobile = false;

  const { width } = useWindowDimensions();

  let baseReached = false;
  if (width <= 1200) {
    baseReached = true;
  }

  if (screenWidths.md > width) {
    mobile = true;
  }

  const location = useLocation();

  // If we are in the context of getit payments, do not show the header on these pages
  if (
    (location.pathname.includes("/order") ||
      location.pathname.includes("/checkout") ||
      location.pathname.includes("/pay") ||
      location.pathname.includes("/myorders")) &&
    brandContextData._id === "a5121e36-getit-technologies"
  ) {
    return null;
  }

  const { ghostEventStores, stadiumListing } = brandContextData;
  let stadiums = [];
  if (!brandContextData.isChain && stadiumListing?.length) {
    stadiums = stadiumListing.filter(
      (fStadium) =>
        fStadium._id !== "k8dfXum85newkBBE4" &&
        fStadium._id !== "GYT54hH3dxCQ7PNSy"
    );
    if (ghostEventStores) {
      stadiums = stadiums.filter(
        (fStadium) => !fStadium.stadTypes.includes("STT_VENUE")
      );
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: theme.token.colorPrimary,
      }}
    >
      <Row
        style={{
          paddingBottom: 10,
          paddingTop: 10,
          position: brandContextData?.headerFixed ? "sticky" : "initial",
          zIndex: 999,
          width: 1200,
          top: 0,
          paddingLeft: baseReached ? 12 : 0,
          paddingRight: baseReached ? 12 : 0,
        }}
      >
        <Col
          span={24}
          style={{
            justifyContent: mobile ? "center" : "flex-start",
            display: "flex",
            marginBottom: 10,
          }}
        >
          {!loadingBrandContextData && brandContextData.brandLogo ? (
            <Image
              onClick={() => navigate("/")}
              width={mobile ? 200 : 300}
              preview={false}
              src={brandContextData.brandLogo}
              className="headerSolutionLogo"
              style={{
                objectPosition: mobile ? "center" : "left",
                maxHeight: mobile ? 100 : 125,
              }}
            />
          ) : null}
        </Col>
        <Col
          span={24}
          style={{
            justifyContent: mobile ? "center" : "initial",
            marginTop: 5,
            marginBottom: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: mobile ? "center" : "space-between",
            }}
          >
            <Row
              gutter={[12, 12]}
              style={{ justifyContent: !mobile ? "initial" : "center" }}
            >
              {/* {!brandContextData?.acceptsDonations &&
              !brandContextData?.announcements?.length ? ( */}
              <Col>
                <Button
                  className="drawerButton"
                  size={mobile ? "middle" : "large"}
                  type="secondary"
                  style={{
                    fontWeight: "bold",
                    backgroundColor: theme.token.colorSecondary,
                    color: theme.token.colorPrimary,
                  }}
                  onClick={() => {
                    if (location.pathname === "/") {
                      if (
                        (!brandContextData.isChain && stadiums.length) ||
                        (brandContextData.isChain &&
                          featuredItems?.length &&
                          locationSelected)
                      ) {
                        executeScroll();
                      }
                    } else {
                      navigate("/", {
                        state: { navigateToOrders: "true" },
                      });
                    }
                  }}
                >
                  Order Now
                </Button>
              </Col>
              {/* // ) : null} */}

              {!loadingBrandContextData && brandContextData?.showAboutButton ? (
                <Col>
                  <Button
                    size={mobile ? "middle" : "large"}
                    type="primary"
                    onClick={() => navigate("/about-us")}
                    style={{
                      color: theme.token.colorSecondary,
                      border: `solid ${theme.token.colorSecondary} 1px`,
                    }}
                  >
                    About Us
                  </Button>
                </Col>
              ) : null}
              {!loadingBrandContextData &&
              brandContextData?.announcements?.length > 0 ? (
                <Col>
                  <Button
                    size={mobile ? "middle" : "large"}
                    type="primary"
                    onClick={() =>
                      setShowAnnouncementDrawer(!showAnnouncementDrawer)
                    }
                    style={{
                      color: theme.token.colorSecondary,
                      border: `solid ${theme.token.colorSecondary} 1px`,
                    }}
                  >
                    Announcements
                  </Button>
                </Col>
              ) : null}

              {!loadingBrandContextData && brandContextData.acceptsDonations ? (
                <Col>
                  <Button
                    size={mobile ? "middle" : "large"}
                    type="primary"
                    style={{
                      color: theme.token.colorSecondary,
                      border: `solid ${theme.token.colorSecondary} 1px`,
                    }}
                    onClick={() => {
                      if (
                        brandContextData._id ===
                        "6463e78850ef8a1188c12dce-ottawa-dragonboat"
                      ) {
                        navigate("/donate");
                      } else {
                        setShowDrawer(!showDrawer);
                      }
                    }}
                  >
                    {brandContextData.donationButtonTitle
                      ? brandContextData.donationButtonTitle
                      : "Donate"}
                  </Button>
                </Col>
              ) : null}
            </Row>
            {!mobile ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/cart")}
              >
                <Text
                  style={{
                    fontSize: 18,
                    color: theme.token.colorTertiary,
                    paddingRight: 7,
                  }}
                >
                  Cart
                </Text>
                <IoMdCart
                  style={{
                    color: theme.token.colorTertiary,
                    fontSize: mobile ? 24 : 32,
                  }}
                />
              </div>
            ) : null}
          </div>
        </Col>
        {brandContextData?.acceptsDonations &&
        brandContextData?.announcements?.length &&
        mobile ? (
          <Col
            span={24}
            style={{
              justifyContent: "center",
              display: "flex",
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            <Button
              size={mobile ? "middle" : "large"}
              type="secondary"
              style={{
                fontWeight: "bold",
                backgroundColor: theme.token.colorSecondary,
                color: theme.token.colorPrimary,
              }}
              onClick={() => {
                if (location.pathname === "/") {
                  if (
                    (!brandContextData.isChain && stadiums.length) ||
                    (brandContextData.isChain &&
                      featuredItems?.length &&
                      locationSelected)
                  ) {
                    executeScroll();
                  }
                } else {
                  navigate("/", { state: { navigateToOrders: "true" } });
                }
              }}
            >
              Order Now
            </Button>
          </Col>
        ) : null}

        {mobile ? (
          <div
            style={{
              position: "absolute",
              right: 25,
              top: 20,
              cursor: "pointer",
            }}
            onClick={() => navigate("/cart")}
          >
            <IoMdCart
              style={{
                color: theme.token.colorTertiary,
                fontSize: mobile ? 24 : 32,
              }}
            />
          </div>
        ) : null}

        <Col span={24}>
          <AnnouncementDrawer
            showAnnouncementDrawer={showAnnouncementDrawer}
            brandContextData={brandContextData}
            setShowAnnouncementDrawer={setShowAnnouncementDrawer}
          />
          <DonateDrawer
            showDrawer={showDrawer}
            brandContextData={brandContextData}
            setShowDrawer={setShowDrawer}
            loadingBrandContextData={loadingBrandContextData}
            mobile={mobile}
          />
        </Col>
        <SelectLocationModal
          mobile={mobile}
          showSelectLocationModal={showSelectLocationModal}
          setShowSelectLocationModal={setShowSelectLocationModal}
        />
      </Row>
    </div>
  );
};

export default WhiteLabelHeader;
