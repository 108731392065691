import {
  Button,
  Col,
  Drawer,
  Input,
  Row,
  Spin,
  Typography,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { useBrandContext } from "./contexts/Brand";
import {
  randomizeArray,
  screenWidths,
  encodeParamString,
} from "./const/const_functions";
import useWindowDimensions from "./hooks/useWindowDimensions";
import MerchantBanner from "./components/MerchantBanner";
import QuickAccessSection from "./components/quickAccessSections/QuickAccessSectionVar1";
import { Content } from "antd/es/layout/layout";
import SearchBar from "./components/SearchBar";
import { useServer } from "./contexts/Server";
import { useCarts } from "./contexts/Carts";
import { useChain } from "./contexts/Chain";
import { useNavigate } from "react-router-dom";
import AdSpace from "./components/AdSpace";
import SocialLinks from "./components/SocialLink";
import { useLocation } from "react-router-dom";
import EventsSection from "./components/EventsSection";
import FeaturedItemsSection from "./components/FeaturedItemsSection";
import StadiumListing from "./components/StadiumListing";
import SelectLocationModal from "./components/SelectLocationModal";
import OrderGeneration from "./components/demo/OrderGeneration";
import JumbotronVar1 from "./components/jumbotrons/JumbotronVar1";
import QuickAccessSectionVar1 from "./components/quickAccessSections/QuickAccessSectionVar1";
const { Text } = Typography;

const App = ({
  refProp,
  eventsRefProp,
  executeScroll,
  executeScrollToEvents,
}) => {
  const { brandContextData, loadingBrandContextData } = useBrandContext();
  const { serverData } = useServer();
  const { multiCart } = useCarts();
  const { locationSelected } = useChain();
  const navigate = useNavigate();

  const [stadiumListing, setStadiumListing] = useState([]);
  const [loadingStadiumListing, setLoadingStadiumListing] = useState(true);
  const [filteredStadiumListing, setFilteredStadiumListing] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [eventStadiumListing, setEventStadiumListing] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [showSelectLocationModal, setShowSelectLocationModal] = useState(false);

  const [quickAccessData, setQuickAccessData] = useState([]);
  const [jumbotronData, setJumbotronData] = useState([]);
  const [vendorTitleListing, setVendorTitleListing] = useState("");
  const [numOrdersToGen, setNumOrdersToGen] = useState(10);
  const [sections, setSections] = useState([]);

  let mobile = false;
  const { width } = useWindowDimensions();
  if (screenWidths.md > width) {
    mobile = true;
  }

  const { state } = useLocation();
  const { navigateToOrders } = state || {};
  const { navigateToEvents } = state || {};

  useEffect(() => {
    if (navigateToOrders === "true") {
      executeScroll();
    }
    if (navigateToEvents === "true") {
      executeScrollToEvents();
    }
  }, [navigateToOrders, stadiumListing, navigateToEvents]);

  const getStadiumListing = () => {
    let stadiumListing = brandContextData.stadiumListing;
    // Sort businesses by nowDoInHouse
    stadiumListing = stadiumListing.sort((a, b) => {
      if (a.nowDoInHouse < b.nowDoInHouse) {
        return 1;
      } else if (a.nowDoInHouse > b.nowDoInHouse) {
        return -1;
      } else {
        return 0;
      }
    });

    setStadiumListing(stadiumListing);

    if (searchValue) {
      stadiumListing = stadiumListing.filter((fStadium) =>
        fStadium.name.includes(searchValue)
      );
    }

    const { ghostEventStores } = brandContextData;
    if (ghostEventStores) {
      stadiumListing = stadiumListing.filter(
        (fStadium) => !fStadium.stadTypes.includes("STT_VENUE")
      );
    }

    stadiumListing = stadiumListing.filter(
      (fStadium) =>
        fStadium._id !== "k8dfXum85newkBBE4" &&
        fStadium._id !== "GYT54hH3dxCQ7PNSy"
    );

    setFilteredStadiumListing(stadiumListing);
    setLoadingStadiumListing(false);
  };

  useEffect(() => {
    if (brandContextData?.stadiumListing) {
      getStadiumListing();
    }
  }, [brandContextData]);

  useEffect(() => {
    if (serverData) {
      getHomePage();
    }
  }, [serverData]);

  const getHomePage = async () => {
    console.log("Get Home Page Data");
    let homePageRes;

    try {
      homePageRes = await serverData.call("MIKO_getHomePage", {
        contextId: brandContextData._id,
      });
      // console.log(homePageRes);
      setQuickAccessData(
        homePageRes?.quickAccess ? homePageRes.quickAccess : null
      );
      setJumbotronData(
        homePageRes?.jumbotron ? randomizeArray(homePageRes.jumbotron) : null
      );
      setVendorTitleListing(
        homePageRes?.vendorsTitleAboveListing
          ? homePageRes.vendorsTitleAboveListing
          : "Vendors"
      );
      setSections(
        homePageRes?.sections
          ? homePageRes.sections.sort((a, b) => a.renderOrder - b.renderOrder)
          : []
      );
    } catch (e) {
      console.log("Error getting homePageRes", e);
    }
  };

  const filterStadiumsBySearch = () => {
    let stads = [];
    const { ghostEventStores } = brandContextData;
    if (ghostEventStores) {
      stads = stadiumListing.filter(
        (fStadium) => !fStadium.stadTypes.includes("STT_VENUE")
      );
    } else {
      stads = stadiumListing;
    }

    stads = stads.filter((fStadium) =>
      fStadium.name.toLowerCase().includes(searchValue.toLowerCase())
    );

    setFilteredStadiumListing(stads);
  };

  useEffect(() => {
    if (stadiumListing.length) {
      filterStadiumsBySearch();
    }
  }, [searchValue]);

  const [open, setOpen] = useState(false);
  const [ordersGenerated, setOrdersGenerated] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const [ordersForPerf, setOrdersForPerf] = useState([]);
  const [queuingText, setQueuingText] = useState("");

  const generateOrders = async () => {
    const res = await serverData.call("createRawOrdezForLoad", {
      contextId: brandContextData._id,
      pageSize: Number(numOrdersToGen),
    });

    const { rawOrderz } = res;

    if (rawOrderz.length) {
      setOrdersGenerated(true);
    }
    setOrdersForPerf(rawOrderz);
  };

  const startOrderGeneration = async () => {
    let newText = `STARTING...`;
    setQueuingText((prevText) =>
      prevText ? prevText + "RFX" + newText : newText
    );
    for (let order of ordersForPerf) {
      try {
        newText = `Creating new order: ${order.orderNo}`;
        setQueuingText((prevText) =>
          prevText ? prevText + "RFX" + newText : newText
        );

        const orderID = await serverData.call("rfx_v2_xmod_addOrder", order);

        newText = `Order created, Paying for order...`;
        setQueuingText((prevText) =>
          prevText ? prevText + "RFX" + newText : newText
        );

        let paymentXYZObj = {
          paymentXType: "xpayment-entered-card",
          nonce: "cnon:card-nonce-ok",
        };
        const verifiedBuyer = null;

        await serverData.call("USSR_makePayment", {
          orderID,
          userAppCalculatedOrder: order.userAppCalculatedOrder,
          userAppTipped: order.userAppTipped,
          paymentXYZObj,
          verifiedBuyer,
        });

        const delayTime = Math.floor(Math.random() * 4000) + 1000; // Generate random delay between 1 to 5 seconds
        newText = `Order payed. Waiting ${delayTime} milliseconds before starting next order...`;
        setQueuingText((prevText) => prevText + "RFX" + newText);

        await delay(delayTime);
      } catch (err) {
        console.log(err);
      }
    }
    newText = `COMPLETE...`;
    setQueuingText((prevText) =>
      prevText ? prevText + "RFX" + newText : newText
    );
  };

  const showBusinessOfferings = () => {
    const stadium = stadiumListing.find(
      (stadium) => stadium._id === locationSelected._id
    );
    const { name, _id } = stadium;
    const stadiumNameDashes = name.replaceAll(" ", "-");
    let encodedStoreId = encodeParamString(_id);
    const newStoreUrl = `/order/${stadiumNameDashes}?glb=${encodedStoreId}`;
    navigate(newStoreUrl, {
      state: {
        stadium: stadium,
      },
    });
  };

  let isCartEmpty = true;
  if (brandContextData?.isChain && locationSelected) {
    const stadiumCart = multiCart.find(
      (cart) => cart.stadiumID === locationSelected._id
    );
    if (stadiumCart) {
      isCartEmpty = false;
    }
  }

  let baseReached = false;
  if (width <= 1200) {
    baseReached = true;
  }

  return (
    <div>
      {!loadingBrandContextData ? (
        <Content>
          <OrderGeneration
            showDrawer={showDrawer}
            onClose={onClose}
            open={open}
            numOrdersToGen={numOrdersToGen}
            setNumOrdersToGen={setNumOrdersToGen}
            generateOrders={generateOrders}
            ordersGenerated={ordersGenerated}
            startOrderGeneration={startOrderGeneration}
            queuingText={queuingText}
            setQueuingText={setQueuingText}
          />
          {/* Dynamically generated sections below */}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Row
              style={{
                paddingTop: quickAccessData && quickAccessData.length ? 0 : 0,
                justifyContent: "center",
              }}
            >
              {/* <Spin spinning={loadingStadiumListing} size="large">
                {brandContextData?.isChain ? (
                  <FeaturedItemsSection mobile={mobile} />
                ) : (
                  <Row style={{ justifyContent: "center" }}>
                    <Col
                      xs={{ span: 22, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      md={{ span: 20, offset: 0 }}
                      lg={{ span: 18, offset: 0 }}
                      xl={{ span: 18, offset: 0 }}
                    >
                      <StadiumListing
                        filteredStadiumListing={filteredStadiumListing}
                        searchValue={searchValue}
                      />
                    </Col>
                  </Row>
                )}
              </Spin> */}
              {sections
                ?.sort((a, b) => a.renderOrder - b.renderOrder)
                .map((sectionData, index) => {
                  if (sectionData?.section?.type === "jumbotron") {
                    return (
                      <Row style={{ width: 1200 }}>
                        <JumbotronVar1
                          jumbotronData={sectionData?.section?.value}
                          mobile={mobile}
                        />
                      </Row>
                    );
                  }

                  if (sectionData?.section?.type === "quickaccess") {
                    return (
                      <Row style={{ width: 1200 }}>
                        <QuickAccessSectionVar1
                          mobile={mobile}
                          quickAccessData={sectionData?.section?.value}
                        />
                      </Row>
                    );
                  }

                  if (sectionData?.section?.type === "eventsListing") {
                    return (
                      <Col span={24}>
                        <EventsSection
                          title={sectionData?.section?.value}
                          eventsRefProp={eventsRefProp}
                          mobile={mobile}
                          upcomingEvents={upcomingEvents}
                        />
                      </Col>
                    );
                  }

                  if (sectionData?.section?.type === "storeListing") {
                    return (
                      <>
                        <div
                          style={{ width: "100%", height: mobile ? 30 : 40 }}
                        />
                        {stadiumListing?.length &&
                        sectionData?.section?.value &&
                        sectionData?.section?.value !== "none" ? (
                          <Row
                            style={{
                              width: 1200,
                            }}
                          >
                            <Col span={24}>
                              <Row
                                style={{
                                  justifyContent: "center",
                                  marginBottom: mobile ? 40 : 75,
                                  marginTop: 75,
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: mobile ? 22 : 28,
                                    fontWeight: "600",
                                  }}
                                >
                                  {sectionData?.section?.value}
                                </Text>
                              </Row>
                            </Col>
                          </Row>
                        ) : null}

                        {stadiumListing.length > 3 &&
                        !brandContextData?.isChain ? (
                          <Row style={{ width: 1200 }}>
                            <SearchBar
                              mobile={mobile}
                              setSearchValue={setSearchValue}
                              vendorTitleListing={vendorTitleListing}
                            />
                          </Row>
                        ) : null}
                        <Row style={{ width: 1200 }}>
                          <Col span={24}>
                            <StadiumListing
                              mobile={mobile}
                              filteredStadiumListing={filteredStadiumListing}
                              searchValue={searchValue}
                            />
                          </Col>
                        </Row>
                      </>
                    );
                  }

                  if (sectionData.section.type === "basic/dynamic") {
                    return (
                      <Row
                        style={{
                          width: 1200,
                          paddingLeft: baseReached ? 12 : 0,
                          paddingRight: baseReached ? 12 : 0,
                        }}
                      >
                        <Col
                          span={24}
                          key={index}
                          style={{
                            marginBottom: 100,
                            paddingLeft: mobile ? 12 : 0,
                            paddingRight: mobile ? 12 : 0,
                            marginTop: mobile ? 25 : 75,
                          }}
                        >
                          <Row
                            style={{
                              justifyContent: "center",
                              backgroundColor:
                                sectionData?.sectionBg ?? "initial",
                            }}
                          >
                            <Col span={24}>
                              <Row
                                gutter={
                                  sectionData.section.value.gutter
                                    ? [
                                        sectionData.section.value.gutter,
                                        sectionData.section.value.gutter,
                                      ]
                                    : [0, 0]
                                }
                              >
                                {sectionData.section.value.columns
                                  .sort((a, b) =>
                                    mobile
                                      ? a.mobileColRenderPosition -
                                        b.mobileColRenderPosition
                                      : a.colRenderPosition -
                                        b.colRenderPosition
                                  )
                                  .map((column, index) => {
                                    let colWidth = column?.colWidth ?? 24;

                                    if (mobile) {
                                      colWidth = column?.mobileColWidth ?? 24;
                                    }

                                    return (
                                      <Col
                                        key={column.index}
                                        span={colWidth ?? 24}
                                        style={
                                          column?.verticallyAlignItems
                                            ? { alignSelf: "center" }
                                            : {}
                                        }
                                      >
                                        {column?.content
                                          ?.sort((a, b) =>
                                            mobile
                                              ? a.mobileOrder - b.mobileOrder
                                              : a.order - b.order
                                          )
                                          .map((contentItem, index) => {
                                            return (
                                              <React.Fragment key={index}>
                                                {contentItem?.type ===
                                                "image" ? (
                                                  <>
                                                    {contentItem.linkURL ? (
                                                      <a
                                                        href={
                                                          contentItem?.linkURL
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        <img
                                                          src={
                                                            contentItem?.value
                                                          }
                                                          alt="Content"
                                                          border="0"
                                                          style={{
                                                            borderRadius: 12,
                                                            width: "100%",
                                                            maxHeight: "100%",
                                                            objectFit: "cover",
                                                          }}
                                                        />
                                                      </a>
                                                    ) : (
                                                      <img
                                                        src={contentItem?.value}
                                                        alt="Content"
                                                        border="0"
                                                        style={{
                                                          borderRadius: 12,
                                                          width: "100%",
                                                          maxHeight: "100%",
                                                          objectFit: "cover",
                                                        }}
                                                      />
                                                    )}
                                                  </>
                                                ) : null}
                                                {contentItem?.type ===
                                                "text/heading" ? (
                                                  <h2>{contentItem?.value}</h2>
                                                ) : null}
                                                {contentItem?.type ===
                                                "text/description" ? (
                                                  <p style={{ fontSize: 16 }}>
                                                    {contentItem?.value}
                                                  </p>
                                                ) : null}
                                                {contentItem?.type ===
                                                "button" ? (
                                                  <Button
                                                    type="ghost"
                                                    size="large"
                                                    onClick={() => {
                                                      return contentItem?.linkURL
                                                        ? window.open(
                                                            contentItem?.linkURL,
                                                            "_blank"
                                                          )
                                                        : null;
                                                    }}
                                                    style={{
                                                      fontWeight: "600",
                                                      backgroundColor: "black",
                                                      color: "white",
                                                    }}
                                                  >
                                                    {contentItem?.value}
                                                  </Button>
                                                ) : null}
                                              </React.Fragment>
                                            );
                                          })}
                                      </Col>
                                    );
                                  })}
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  }

                  return null;
                })}
            </Row>
          </div>

          {brandContextData?.isChain && locationSelected && mobile ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "rgba(255,255,255,0.5)",
                position: "fixed",
                bottom: 0,
                zIndex: 1,
                width: "100%",
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              <Button
                style={{
                  position: "relative",
                  height: 50,
                  width: 300,
                  fontWeight: "bold",
                  color: brandContextData.brandTheme.colorTertiary,
                  fontSize: 18,
                  borderWidth: 0,
                  backgroundColor: brandContextData.brandTheme.colorSecondary,
                }}
                onClick={showBusinessOfferings}
              >
                {isCartEmpty ? "Start an Order" : "Continue Order"}
              </Button>
            </div>
          ) : null}
          {!loadingBrandContextData && brandContextData.brandSocial?.length ? (
            <Col
              style={{ paddingBottom: 10 }}
              xs={{ span: 24 }}
              md={{ span: 16, offset: 4 }}
            >
              <SocialLinks
                brandSocial={brandContextData.brandSocial}
                titleText={brandContextData.brandSocialTitleText}
                iconColor={brandContextData.brandTheme.colorSecondary}
                mobile={mobile}
              />
            </Col>
          ) : null}
        </Content>
      ) : null}
      <SelectLocationModal
        mobile={mobile}
        showSelectLocationModal={showSelectLocationModal}
        closeModal={() => setShowSelectLocationModal(false)}
        stadiumListing={stadiumListing}
      />
    </div>
  );
};

export default App;
